import {
   initChatSettingsData,
   updateChatSettingsData,

   getFilterMembers,
   fetchChatConversations,
   fetchChatConversationMessages,
   chatInit,
   getMember,
   fetchConversationById,

   fetchLikedMemberByMessage,

   getConversationWithUser,
   membershipPricingById,
   toggleOpenCloseConversation,
   togglePinUnpinConversation,
   controllersByTypes,
} from 'admin/api/AuthApi';
import * as actions from "./actions";
import toast from "common/utils/toast";
import {
   membershipsFetchOperation,
} from 'admin/state/modules/members/operations';
import QueryParams from 'admin/utils/QueryParams';
import Router from 'admin/routes/router';
import { currentLocationPathSelector } from '../router/selectors';
import { conversationsFilterSelector, conversationsListSelector, initedConversationSelector } from './selectors';

//start chat setting operations
export const fetchChatSettingsOperation = () => {
   return async (dispatch) => {
      dispatch(actions.fetchChatSettingsRequest())
      try {
         const { data } = await initChatSettingsData();
         dispatch(actions.fetchChatSettingsCompleted(data))
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchChatSettingsFailed(error.response))
         }
      }
   };
};
export const updateChatSettingsOperation = (data, newSettings) => {
   return async (dispatch) => {
      dispatch(actions.updateChatSettingsRequest())
      try {
         let sendData = { ...data };
         if('chat_private_active_different_members_payed_messaging_payload_array' in sendData){
            delete sendData.chat_private_active_different_members_payed_messaging_payload_array
         }
         if('chat_private_active_different_others_payed_messaging_payload_array' in sendData){
            delete sendData.chat_private_active_different_others_payed_messaging_payload_array
         }
         await updateChatSettingsData(sendData);
         toast.info('Your change has been saved')
         let updateData = newSettings
         if(!newSettings) {
            updateData = data;
         }
         dispatch(actions.updateChatSettingsCompleted(updateData))
      } catch (error) {
         if(error.response) {
            toast.error('Please fix errors');
            dispatch(actions.updateChatSettingsFailed(error.response))
         }
      }
   };
};

//end chat setting operations

//start chat members list operations

export const membersFetchDetailsOperation = (query) => {
   return async (dispatch) => {
      dispatch(actions.fetchChatMembersRequest())
      try {
         dispatch(membershipsFetchOperation())
         const { data } =  await getFilterMembers(query);
         dispatch(actions.fetchChatMembersCompleted(data))
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchChatMembersFailed(error.response))
         }
      }
   };
};

export const membersFilterDetailsOperation = (query, bool) => {
   return async (dispatch) => {
      dispatch(actions.chatMembersFilterRequest(bool))
      try {
         const { data } =  await getFilterMembers(query);
         dispatch(actions.chatMembersFilterCompleted(data, bool))
      } catch (error) {
         if(error.response) {
            dispatch(actions.chatMembersFilterFailed(error.response))
         }
      }
   };
};
//end chat members list

// start chat Conversations
export const fetchConversationsOperation = (query = [], isFilter, inited, searchText, callback) => {
   return async (dispatch, getState) => {
      if(!inited){
         dispatch(actions.fetchConversationsRequest(isFilter))
      }
      try {
         if(!inited) {
            let init = null
            const isChatInited = initedConversationSelector(getState())
            if(!isFilter || !isChatInited) {
               init =  await chatInit();

               // Save chat init state earlyer, to show unread count faster
               dispatch(actions.chatInitCompleted({
                  group: { ...init.data.group_chat, attachments: [], attachmentsForShow: [] },
                  unread_private_messages_count: init.data.unread_private_messages_count,
               }))
            }
            const { data } = await fetchChatConversations(query, isFilter);
            let newData = {
               data: data,
            }
            if(init) {
               newData = {
                  ...newData,
                  group: { ...init.data.group_chat, attachments: [], attachmentsForShow: [] },
                  unread_private_messages_count: init.data.unread_private_messages_count,
               }
            }
            dispatch(actions.fetchConversationsCompleted({ data: newData, isFilter, searchText, isChatInited }))
            if(callback) {
               // On callback we select grop chat, but in case it doenst been updated yet
               callback(init?.data?.group_chat)
            }
         }
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchConversationsFailed(error.response))
         }
      }
   };
};
// end chat Conversation messages


// start chat conversations
export const fetchConversationMessagesOperation = (activeConversation, groupChat, member, callback, isScroll = true, searchValue, dontAddConversationToList) => {
   return async (dispatch, getState) => {
      if(activeConversation.chat_room_id && Number(activeConversation.chat_room_id)){
         let isGroup = !!groupChat.chat_room_id && Number(groupChat.chat_room_id) === Number(activeConversation.chat_room_id)
         dispatch(actions.fetchConversationMessagesRequest())
         dispatch(actions.setChatConversationAction({ ...activeConversation, isGroup }))
      }
      try {
         let activeConversationInfo = {}
         let uuid = activeConversation.user_uuid
         const id = activeConversation.chat_room_id
         let data = {}
         if(!activeConversation.isFake) {
            const res =  await fetchChatConversationMessages([], id);
            data = res.data
         }
         let isGroup = !!groupChat.chat_room_id && Number(groupChat.chat_room_id) === Number(activeConversation.chat_room_id)
         if(getState().chat.cancelFlag) {
            return
         }
         dispatch(actions.setChatConversationAction({ ...activeConversation, isGroup: isGroup }))

         if(activeConversation && (!activeConversation.user_uuid || !activeConversation.conversation_name)){
            if(isGroup) {
               activeConversationInfo = groupChat
               uuid = groupChat.last_message ?  groupChat.last_message.user_uuid : null
               if(member.authUserUUid === uuid){
                  let memberMessage = data.data.find(el => el.user.uuid !== member.authUserUUid)
                  uuid = memberMessage ? memberMessage.user.uuid : null
               }
               if(getState().chat.cancelFlag) {
                  return
               }
               if(uuid) {
                  dispatch(fetchMemberByUuidOperation(uuid, member.isOpen, true))
               } else {
                  dispatch(actions.fetchMemberByUuidAction({ data: {}, isGroup: true, loadData: false }))
                  activeConversationInfo = { ...activeConversationInfo, member: {} }
               }

            } else if(!activeConversation.isFake) {
               const conversationsList = conversationsListSelector(getState())
               let innerConversation = conversationsList.data.find(item => item.chat_room_id === id)
               // Fetch conversation if it not exist on list
               if(!innerConversation) {
                  const response = await fetchConversationById(id)
                  innerConversation = response.data
               }
               uuid = innerConversation.user_uuid
               if(uuid) {
                  dispatch(fetchMemberByUuidOperation(uuid, member.isOpen, !innerConversation.user_uuid))

                  // when loading on progress user able to change open/closed state
                  let noNeedToAddToList = dontAddConversationToList
                  let conversationsFilter = conversationsFilterSelector(getState())
                  const isFilteredByClosed = conversationsFilter?.type?.[0] === 'closed'
                  if(Router.route('CHAT').match(currentLocationPathSelector(getState())) && isFilteredByClosed) {
                     noNeedToAddToList = true
                  }

                  dispatch(actions.addRemoveConverstionItemAction(innerConversation, searchValue, noNeedToAddToList))
                  activeConversationInfo = { ...innerConversation }
               }
            }
         }
         if(getState().chat.cancelFlag) {
            return
         }
         if(Number(id)){
            dispatch(actions.fetchConversationMessagesCompleted({ [Number(id)]: { ...data } }))
         }
         dispatch(actions.setChatConversationAction({ ...activeConversation, ...activeConversationInfo }))
         if(isScroll) {
            const activeConversationItem = document.querySelector(`#ams_converstion_by_${ id }`)
            const converstionList = document.querySelector(`.chat-left-content`)
            let diff = 0
            if(activeConversationItem) {
               diff = activeConversationItem.offsetTop - 88 - converstionList.scrollTop
               if(diff > window.innerHeight / 2 || diff < 0) {
                  converstionList.scrollTo({
                     top: activeConversationItem.offsetTop - 200,
                     left: 0,
                     behavior: 'smooth',
                  })
               }
            }

         }
         if(callback) {
            callback()
         }
      } catch (error) {
         const controller = controllersByTypes.fetchChatConversationMessages

         if(error.response || (controller.signal?.reason && 'ABORTED_MANUAL' === controller.signal.reason)) {
            dispatch(actions.fetchConversationMessagesFailed(error.response))
         }
      }
   };
};
export const fetchNewConversationOperation = (query) => {
   return async (dispatch) => {
      dispatch(actions.fetchNewConversationRequest())
      try {
         const { data } =  await fetchChatConversations(query);

         dispatch(actions.fetchNewConversationCompleted(data))
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchNewConversationFailed(error.response))
         }
      }
   };
};
export const fetchNewMessagesOperation = (query = [], id) => {
   return async (dispatch) => {
      dispatch(actions.fetchNewMessagesRequest())
      try {
         console.log(query);
         const { data } =  await fetchChatConversationMessages(query, id);
         dispatch(actions.fetchNewMessagesCompleted(data))
      } catch (error) {
         if(error.response || 'ERR_CANCELED' === error?.code) {
            dispatch(actions.fetchNewMessagesFailed(error.response))
         }
      }
   };
};
// end chat conversations messages

export const fetchLikedMemberByMessageOperation = (query, id) => {
   return async (dispatch) => {
      dispatch(actions.fetchLikedMemberByMessageRequest(!!query))
      try {
         const { data } =  await fetchLikedMemberByMessage(query, id);
         dispatch(actions.fetchLikedMemberByMessageCompleted({ data, isPagination: !!query }))
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchLikedMemberByMessageFailed(error.response))
         }
      }
   };
};


export const createConversationOperation = (member, initedConverstionMessages, screenWidth, searchValue, callback) => {
   return async (dispatch) => {
      // dispatch(actions.fetchLikedMemberByMessageRequest(!!query))
      try {
         const { uuid, avatar_compressed_src, username } = member
         const { data } =  await getConversationWithUser(uuid);
         let conversation = { ...data, user_uuid: uuid }
         if(!data.chat_room_id) {
            conversation = {
               ...conversation,
               user_avatar: avatar_compressed_src,
               username,
               attachments: [],
               attachmentsForShow: [],
            }

            let randomid = Math.floor(Math.random() * (70000000 - 1414) + 1414);

            if(window?.new_conversations && !window?.new_conversations[`new_${ uuid }`]) {
               window.new_conversations = {
                  ...window?.new_conversations,
                  ['new_' + uuid]: randomid,
               }
            } else {
               window.new_conversations = {
                  ['new_' + uuid]: randomid,
               }
            }
         }

         if(window?.new_conversations && window?.new_conversations?.[`new_${ uuid }`]) {
            QueryParams.setQueryParam('conversation', `${ window?.new_conversations?.[`new_${ uuid }`] || 'new' }`)
         } else {
            QueryParams.setQueryParam('conversation', `${ data.chat_room_id || 'new' }`)
         }

         if(data.chat_room_id) {
            if(!initedConverstionMessages[data.chat_room_id]) {
               dispatch(actions.fetchMemberByUuidAction({
                  loadData: true,
               }))
               dispatch(fetchConversationMessagesOperation(data, {}, { isOpen: screenWidth > 1360 ? true : null }, callback, false, searchValue, true))
            } else {
               dispatch(actions.setChatConversationAction({ ...data, member }))
               if(callback) {
                  callback()
               }
            }
         } else {
            dispatch(fetchMemberByUuidOperation(member.uuid, screenWidth > 1360 ? true : null))
            if(callback) {
               callback()
            }
         }
         dispatch(actions.createConversation(conversation, member, searchValue))
      } catch (error) {
      }
   };
};
export const fetchMemberByUuidOperation = (uuid, isOpen, isGroup) => {
   return async (dispatch) => {
      dispatch(actions.fetchMemberByUuidAction({
         loadData: true,
         isOpen: isOpen,
      }))
      try {
         let state = {}
         const { data } = await getMember(uuid, 'uuid')
         let usd_terms = null
         let membership_description = null
         if(data.subscriber && data.pricing_id) {
            let membership = await membershipPricingById(data.pricing_id)
            usd_terms = membership.data.usd_terms
            usd_terms = membership ? membership.data.usd_terms : null
            membership_description = membership ? membership.data.description : null
         }
         let isOpenModal = isOpen === null ? null : true
         state = { ...state, data: { ...data, usd_terms, membership_description }, isOpen: isOpenModal }

         dispatch(actions.fetchMemberByUuidAction({ ...state,  loadData: false, isGroup }))
      } catch (error) {
         dispatch(actions.fetchMemberByUuidAction({
            loadData: false,
            isOpen: false,
         }))
      }
   };
};

export const toggleOpenCloseConversationOperation = (conversation, callback) => {
   return async (dispatch, getState) => {
      dispatch(actions.fetchingConversationStart(conversation.chat_room_id))
      try {
         let newType = 'closed'
         if('closed' === conversation.type) {
            newType = 'open'
         }
         await toggleOpenCloseConversation(conversation.user_uuid, newType)
         if(callback) {
            callback(getState().chat)
         }
         dispatch(actions.fetchingConversationCompleted(conversation))

      } catch (error) {
         toast.error('Something went wrong')
         dispatch(actions.fetchingConversationFailed(conversation.chat_room_id))
      }
   }
}

export const togglePinUnpinConversationOperation = (conversation, query = []) => {
   return async dispatch => {
      dispatch(actions.fetchingConversationStart(conversation.chat_room_id))
      try {
         await togglePinUnpinConversation(conversation.user_uuid)
         if(conversation.is_pinned) {
            const { data } = await fetchChatConversations(query);
            dispatch(actions.togglePinUnpinConversationCompleted(conversation.chat_room_id, data))
            toast.success('Conversation has been unpinned')
         } else {
            dispatch(actions.togglePinUnpinConversationCompleted(conversation.chat_room_id, null))
            toast.success('Conversation has been pinned')
         }
      } catch (error) {
         toast.error('Something went wrong')
         dispatch(actions.fetchingConversationFailed(conversation.chat_room_id))
      }
   }
}
