import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Input from 'common/components/elements/inputs/inputs';
import IconLabel from 'common/components/elements/icon-label';

const DeleteModal = ({
   isDisabled,
   type,
   deleteCollection,
   onClickCancel,
   title,
   isBulkEdit,
   count,
}) => {


   const [inputValue, setInputValue] = useState('');
   const checkTyped = inputValue.toLowerCase() !== `delete ${ count }`;

   return (
      <div className='w-full p-6 text-center'>
         {
            !isBulkEdit ? (
               <div className='flex flex-col justify-center'>
                  <div className='flex justify-center mb-3'>
                     <IconLabel
                        name='delete'
                        color='error'
                     />
                  </div>
                  <span className='text-major text-xl font-bold'>Delete this {type}?</span>
                  <span className='text-secondary leading-tight mt-4'>This {type} will be permanently deleted</span>
                  <div className={ `mt-8 h-9 mx-auto` }>
                     <Button
                        classNames='h-full flex leading-tight px-3 !min-w-[168px]'
                        text={ `Yes, delete ${ type }` }
                        textColor='error'
                        backgroundColor='transparent'
                        fontWeight='medium'
                        padding='none'
                        textSize='small'
                        onClick={ deleteCollection }
                        loaderColor='red'
                     />
                  </div>
                  <div className='mt-3 h-9 mx-auto'>
                     <Button
                        classNames='h-full flex leading-tight px-3 !w-[164px]'
                        text='Cancel'
                        textColor='secondary'
                        backgroundColor='transparent'
                        fontWeight='medium'
                        padding='none'
                        textSize='small'
                        onClick={ onClickCancel }
                     />
                  </div>
               </div>
            ) : (
               <>
                  <div className='mt-3'>
                     <span className='text-secondary leading-tight mb-3'>{title || `Delete this ${ type }`}</span>
                  </div>
                  <Input name='type_text' autoComplete='off'
                     value={ inputValue }
                     type='text'
                     className='mt-4 mb-2px'
                     onChange={ (name, value) => {
                        setInputValue(value.toLowerCase())
                     } }
                  />
                  <div className='mt-3 h-9 mx-3'>
                     <Button
                        classNames={ `h-full flex leading-tight ${ !!checkTyped ? 'opacity-50' : '' }` }
                        text='Delete'
                        textColor='error'
                        backgroundColor='transparent'
                        fontWeight='medium'
                        padding='none'
                        disabled={ !!checkTyped }
                        onClick={ () => deleteCollection() }
                     />
                  </div>
                  <div className='h-9 mx-3 mt-2'>
                     <Button
                        classNames='h-full flex leading-tight'
                        text='Cancel'
                        textColor='secondary'
                        backgroundColor='transparent'
                        fontWeight='medium'
                        padding='none'
                        onClick={ onClickCancel }
                     />
                  </div>
               </>
            )}

      </div>
   );
};
DeleteModal.propTypes = {
   onClickCancel: PropTypes.func,
   deleteCollection: PropTypes.func,
   isDisabled: PropTypes.bool,
   type: PropTypes.string,
   title: PropTypes.string,
   isBulkEdit: PropTypes.bool,
   count: PropTypes.any,
};
DeleteModal.defaultProps = {
   type: 'tag',
   isBulkEdit: false,
};
export default React.memo(DeleteModal);
