import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Filter from 'common/components/responsive/filter';
import Button from 'common/components/elements/buttons/primary';
import cx from 'classnames';
import MobileFilter from 'common/components/modules/mobile-filter';
import Search from 'common/components/elements/inputs/search';

const INITIAL_FILTERS = {
   status: { name: null,  value: [] },
   tags: { name: null,  value: [] },
   collections: { name: null,  value: [] },
   casts: { name: null,  value: [] },
   sortBy: { name: '', value: 'date_added:desc' },
}

const VideoFilterContent = ({
   resources,
   onFilterChange,
   desktopType,
   onClearAllFilters,
   emptyFilters,
   isInsideModal,
   filtersData,
   type,
   countesState,
   isReorderMode,
   contentType,
   onSearchChange,
   isMobile,
   isMultipleAttachments,
}) => {

   let allSorts = [
      { name: 'Newest', value: 'date_added:desc' },
      { name: 'Oldest', value: 'date_added:asc' },
      { name: 'Name • A - Z', value: 'name:asc' },
      { name: 'Name • Z - A', value: 'name:desc' },
      // { name: 'Date of videography • Newest to oldest', value: 'shooting_date:desc' },
      // { name: 'Date of videography • Oldest to newest', value: 'shooting_date:asc' },
      // { name: 'Views • Highest to lowest', value: 'views_count:desc' },
      // { name: 'Views • Lowest to highest', value: 'views_count:asc' },
      // { name: 'Likes • Highest to lowest', value: 'likes_count:desc' },
      // { name: 'Likes • Lowest to highest', value: 'likes_count:asc' },
      { name: `Comments • Highest to lowest`, value: 'comments_count:desc' },
      { name: `Comments • Lowest to highest`, value: 'comments_count:asc' },
   ]

   const allStatuses = [
      { icon: 'public', name: 'Published ', value: 'published' },
      // { icon: 'clock', name: contentType === 'store' ? 'Prerelease' : 'Scheduled ', value: contentType === 'store' ? 'pre_release' : 'scheduled' },
      { icon: 'clock', name: 'Scheduled ', value: 'scheduled' },
      { icon: 'unpublish', name: 'Unpublished', value: 'unpublished' },
   ]

   const [filters, setFilters] = useState({ ...INITIAL_FILTERS })

   const hideClearButton = !filters?.status?.name && filters.sortBy.value === 'date_added:desc' && !filters?.tags?.name && !filters?.casts?.name && !filters?.collections?.name

   useEffect(() => {
      if(isReorderMode) {
         setFilters({ ...INITIAL_FILTERS })
      }
   }, [isReorderMode])

   useEffect(() => {
      const getDefaultFilters = () => {
         let status = { name: null,  value: [] }
         let tags = { name: null,  value: [] }
         let collections = { name: null,  value: [] }
         let casts = { name: null,  value: [] }
         let sortBy = { name: '', value: 'date_added:desc' }
         function valueOfNumber(arr) {
            var i, n = arr.length;
            for(i = 0; i < n; ++i) {
               arr[i] = +arr[i];
            }
            return
         }

         if(!!filtersData?.status?.length){
            console.log(filtersData);
            let arr = filtersData?.status?.split(',')
            status = { name: `${ arr.length } Selected`, value: arr }
         }
         if(filtersData.tags){
            let arr = filtersData.tags.split(',')
            valueOfNumber(arr)
            tags = { name: `${ arr.length } Selected`, value: arr }
         }
         if(filtersData.collections){
            let arr = filtersData.collections.split(',')
            valueOfNumber(arr)
            collections = { name: `${ arr.length } Selected`, value: arr }
         }
         if(filtersData.casts){
            let arr = filtersData.casts.split(',')
            valueOfNumber(arr)
            casts = { name: `${ arr.length } Selected`, value: arr }
         }
         if(filtersData.sortBy){
            sortBy = allSorts.find(i => i.value === filtersData.sortBy)
         }
         return { status, tags, collections, casts, sortBy }
      }

      if(!isReorderMode && !isInsideModal) {
         let defaultFilters = getDefaultFilters()
         setFilters(defaultFilters)
      }
      if(isMultipleAttachments && isInsideModal) {
         const defaultValue = getDefaultFilters()
         setFilters(defaultValue)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isMultipleAttachments])

   function onMultiplFilter(value, name, type, bool){
      let data = []
      if(filters[type] && filters[type].value && Array.isArray(filters[type].value)) {
         data = filters[type].value
      }
      if(data && Array.isArray(data) && data.includes(value)) {
         data = data.filter(i => i !== value)
      } else {
         data = [...data, value]
      }
      setFilters({
         ...filters,
         [type]: {
            name: data.length === 0 ? null : `${ data.length } Selected`,
            value: data,
         },
      })
      if(bool) {
         onFilterChange(data, type)
      }
   }
   function onSimpleFilter(value, name, type){
      setFilters({
         ...filters,
         [type]: {
            name: value === 'date_added:desc' ? '' : name,
            value,
         },
      })
      onFilterChange(value, type)
   }
   function onClearFilter(isSimple, type, defaultValue){
      let emptyValue = isSimple ? {} : [];
      if(defaultValue) {
         emptyValue = defaultValue
      }
      if(type === 'sortBy') {
         emptyValue = {
            name: '',
            value: allSorts[0].value,
         }
      }

      setFilters({
         ...filters,
         [type]: emptyValue,
      })
      onFilterChange(isSimple ? '' : [], type)
   }

   const onClickClearAll = () => {
      setFilters({ ...INITIAL_FILTERS })
      onClearAllFilters()
   }

   const calculateFiltersCount = () => {
      let count = 0

      Object.keys(filters).forEach(key => {
         if(typeof filters[key].value === 'string') {
            if(key === 'sortBy' && filters[key].value === 'date_added:desc') {
               return;
            } else {
               count += 1
            }
         } else if(Array.isArray(filters[key].value)) {
            count += filters[key].value.length
         }
      })

      return count;
   }

   return (
      <>
         {
            !isMobile && (
               <div
                  className={ cx({
                     'flex flex-wrap z-20 video-filter max-[1300px]:flex-col flex-1 justify-between gap-2': true,
                     // 'max-[1300px]:flex-col justify-between gap-2': true,
                     'max-[1500px]:flex-col': 'livestream' === type,
                     // 'justify-between ': !isInsideModal,
                     // 'filter-inside-modal': isInsideModal,
                  }) }
               >
                  <div
                     className={ cx({
                        'flex z-20 gap-2 !w-[fit-content] flex-wrap': true,
                        'overflow-x-auto video-filter-mobile max-w-full': desktopType === 'mobile',
                        // 'mb-4': !isInsideModal && 'livestream' !== type,
                     }) }
                  >
                     {
                        (!isInsideModal || contentType === 'store') && (
                           <Filter
                              isMultiplSelect
                              name=' Status'
                              type='filter'
                              data={ allStatuses }
                              activeSelectedValue={ filters.status }
                              desktopType={ desktopType }
                              onFilterChange={ (value, name) => onMultiplFilter(value, name, 'status', true) }
                              onClickClear={ () => {
                                 onClearFilter(true, 'status')
                              } }
                              isAutoSave
                              height='10'
                              contentStyles={ {
                                 left: '0px',
                                 top: '44px',
                                 width: '343px',
                              } }
                              filterClassName='flex-none'
                              disabled={ isReorderMode }
                              contentMarginToLeftIcon='3'
                              disabledState={ contentType === 'store' ? ['scheduled'] : [] }
                              disabledOptionTooltiptext='Scheduled content cannot be included in bundles'
                           />
                        )
                     }
                     <Filter
                        isMultiplSelect={ true }
                        isSearchable={ resources.tags && resources.tags.length > 10 }
                        activeSelectedValue={ filters.tags }
                        filterNameKey='name'
                        filterValueKey='id'
                        searchPlaceholder='Search tags'
                        name='Content tags'
                        type='filter'
                        data={ resources.tags || [] }
                        desktopType={ desktopType }
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'tags', bool) }
                        onClickShowResult={ () =>  onFilterChange(filters.tags.value, 'tags') }
                        onClickClear={ () => {
                           onClearFilter(false, 'tags')
                        } }
                        isAutoSave
                        height='10'
                        contentStyles={ {
                           left: '0px',
                           top: '44px',
                        } }
                        filterClassName='flex-none'
                        disabled={ isReorderMode }
                        contentClassName={ `!min-h-[30vh] ms:!min-h-[100%] !max-h-[250px] ` }
                     />
                     <Filter
                        isMultiplSelect={ true }
                        isSearchable={ resources.video_collections && resources.video_collections.length > 10 }
                        activeSelectedValue={ filters.collections }
                        filterNameKey='title'
                        filterValueKey='id'
                        searchPlaceholder='Search collections'
                        name='Collections'
                        type='filter'
                        data={ resources.video_collections || [] }
                        desktopType={ desktopType }
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'collections', bool) }
                        onClickShowResult={ () =>  onFilterChange(filters.collections.value, 'collections') }
                        onClickClear={ () => {
                           onClearFilter(false, 'collections')
                        } }
                        isAutoSave
                        height='10'
                        contentStyles={ {
                           left: '0px',
                           top: '44px',
                        } }
                        filterClassName='flex-none'
                        disabled={ isReorderMode }
                        contentClassName={ `!min-h-[30vh] ms:!min-h-[100%] !max-h-[250px] ` }
                     />
                     <Filter
                        isMultiplSelect={ true }
                        isSearchable={ resources.cast_members && resources.cast_members.length > 10 }
                        activeSelectedValue={ filters.casts }
                        filterNameKey='screen_name'
                        filterValueKey='id'
                        searchPlaceholder='Search cast members'
                        name='Cast members'
                        type='filter'
                        data={ resources.cast_members || [] }
                        desktopType={ desktopType }
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'casts', bool) }
                        onClickShowResult={ () =>  onFilterChange(filters.casts.value, 'casts') }
                        onClickClear={ () => {
                           onClearFilter(false, 'casts')
                        } }
                        isAutoSave
                        height='10'
                        contentStyles={ {
                           left: '0px',
                           top: '44px',
                        } }
                        filterClassName='flex-none'
                        disabled={ isReorderMode }
                        contentClassName={ `!min-h-[30vh] ms:!min-h-[100%] !max-h-[250px] ` }
                     />
                     {
                        ((!emptyFilters && isMultipleAttachments) || (!emptyFilters && !hideClearButton)) && (
                           <div className='h-10 mx-2 whitespace-nowrap inline-block'>
                              <Button
                                 text='Clear all'
                                 textColor='secondary'
                                 backgroundColor='transparent'
                                 textSize='base'
                                 onClick={ onClickClearAll }
                                 waveColor='rgba(106, 106, 106, 0.8)'
                                 classNames='h-full px-2 flex items-center'
                                 padding='none'
                                 iconName='cancel'
                                 fontIconColor='secondary'
                                 fontIconSize='large'
                                 iconPosition='left'
                              />
                           </div>
                        )
                     }
                  </div>
                  <div className='flex flex-row gap-2'>
                     {
                        'livestream' !== type && (
                           <div className='z-10 w-full'>
                              <Filter
                                 name='Sorting'
                                 type='sort'
                                 data={ allSorts }
                                 desktopType={ desktopType }
                                 onFilterChange={ (value, name) => onSimpleFilter(value, name, 'sortBy') }
                                 onClickShowResult={ () => {} }
                                 activeSelectedValue={ {
                                    name: filters.sortBy.name,
                                    value: filters.sortBy.value,
                                 } }
                                 onClickClear={ () => {
                                    onClearFilter(true, 'sortBy')
                                 } }
                                 height='10'
                                 contentStyles={ {
                                    right: '0px',
                                    top: '44px',
                                    zIndex: '1000 !importante',
                                 } }
                                 filterClassName='flex-none'
                                 disabledState={ isReorderMode ? ['name:asc', 'name:desc', 'comments_count:desc', 'comments_count:asc'] : [] }
                                 hasClearButton={ false }
                                 hasFilterButtonContent={ false }
                                 isAutoSave
                                 disabledOptionTooltiptext='This view cannot be reordered'
                              />
                           </div>
                        )
                     }
                     {
                        'livestream' === type && (
                           <div className='z-10'>
                              <Filter
                                 name='Sorting'
                                 type='sort'
                                 data={ allSorts }
                                 desktopType={ desktopType }
                                 onFilterChange={ (value, name) => onSimpleFilter(value, name, 'sortBy') }
                                 onClickShowResult={ () => {} }
                                 activeSelectedValue={ {
                                    name: filters.sortBy.name,
                                    value: filters.sortBy.value,
                                 } }
                                 onClickClear={ () => {
                                    onClearFilter(true, 'sortBy')
                                 } }
                                 contentStyles={ {
                                    left: '0',
                                    top: '44px',
                                    zIndex: '1000 !importante',
                                 } }
                                 filterClassName='flex-none'
                                 height='10'
                                 disabledState={ isReorderMode ? ['name:asc', 'name:desc', 'comments_count:desc', 'comments_count:asc'] : [] }
                                 hasClearButton={ false }
                                 hasFilterButtonContent={ false }
                                 isAutoSave
                                 disabledOptionTooltiptext='This view cannot be reordered'
                              />
                           </div>
                        )
                     }
                  </div>
               </div>
            )
         }
         <div
            className={
               cx({
                  'w-full mt-0 flex items-start gap-3': true,
                  'max-w-[320px]': !isMobile,
               })
            }
         >
            <Search
               onChange={ onSearchChange }
               placeholder={ 'livestream' === type ? 'Search recorded streams' : 'Search videos' }
               isOutSideControll
               value={ filtersData?.search }
               disabled={ isReorderMode }
            />
            {
               isMobile && (
                  <MobileFilter
                     sortOptions={ allSorts }
                     selectedSortValue={ filters.sortBy.value }
                     sortName='sortBy'
                     disabledSortOptions={ isReorderMode ? ['name:asc', 'name:desc', 'comments_count:desc', 'comments_count:asc'] : [] }
                     disabledSortOptionTooltipText='This view cannot be reordered'
                     onFilterChange={ (value, name, filterName, isMultiple) => {
                        if(!isMultiple) {
                           onSimpleFilter(value, name, filterName)
                        } else {
                           onMultiplFilter(value, name, filterName, true)
                        }
                     } }
                     showClearAllFilters={ !emptyFilters && !hideClearButton }
                     onClickClearAllFilters={ onClickClearAll }
                     filtersCount={ calculateFiltersCount() }
                     filtersList={ [
                        {
                           hide: !(!isInsideModal || contentType === 'store'),
                           title: 'Status',
                           isMultiple: true,
                           filterName: 'status',
                           optionNameKey: 'name',
                           optionValueKey: 'value',
                           options: allStatuses,
                           selectedFilter: filters.status,
                           disabled: isReorderMode,
                           disabledOptions: contentType === 'store' ? ['scheduled'] : [],
                           disabledOptionTooltipText: 'Scheduled content cannot be included in bundles',
                        },
                        {
                           title: 'Content tags',
                           isMultiple: true,
                           filterName: 'tags',
                           optionNameKey: 'name',
                           optionValueKey: 'id',
                           options: resources.tags || [],
                           selectedFilter: filters.tags,
                           isSearchable: resources.tags && resources.tags.length > 10,
                           searchPlaceholder: 'Search tags',
                           isEmpty: resources.tags && resources.tags.length === 0,
                           disabled: isReorderMode,
                        },
                        {
                           title: 'Collections',
                           isMultiple: true,
                           filterName: 'collections',
                           optionNameKey: 'title',
                           optionValueKey: 'id',
                           options: resources.video_collections || [],
                           selectedFilter: filters.collections,
                           isSearchable: resources.video_collections && resources.video_collections.length > 10,
                           searchPlaceholder: 'Search collections',
                           isEmpty: resources.video_collections && resources.video_collections.length === 0,
                           disabled: isReorderMode,
                        },
                        {
                           title: 'Cast members',
                           isMultiple: true,
                           filterName: 'casts',
                           optionNameKey: 'screen_name',
                           optionValueKey: 'id',
                           options: resources.cast_members || [],
                           selectedFilter: filters.casts,
                           isSearchable: resources.cast_members && resources.cast_members.length > 10,
                           searchPlaceholder: 'Search cast members',
                           isEmpty: resources.cast_members && resources.cast_members.length === 0,
                           disabled: isReorderMode,
                        },
                     ] }
                  />
               )
            }
         </div>
      </>
   );
};

VideoFilterContent.propTypes = {
   resources: PropTypes.object,
   onFilterChange: PropTypes.func,
   onClearAllFilters: PropTypes.func,
   desktopType: PropTypes.string,
   emptyFilters: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   filtersData: PropTypes.object,
   type: PropTypes.string,
   countesState: PropTypes.object,
   isReorderMode: PropTypes.bool,
   contentType: PropTypes.string,
   onSearchChange: PropTypes.func,
   isMobile: PropTypes.bool,
   isMultipleAttachments: PropTypes.bool,
};
VideoFilterContent.defaultProps = {
   resources: {},
   onFilterChange: () => {},
   onClearAllFilters: () => {},
   type: 'video',
   isReorderMode: false,
   contentType: '',
};

export default React.memo(VideoFilterContent);
