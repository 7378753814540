import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import {
   isFetchConversationMessagesSelector,
   conversationMessagesSelector,
   isEmptyConversationMessagesSelector,
   activeConverstaionSelector,
   isFetchNewConversationMessagesSelector,
   likedMemberByMessageSelector,
   isSocketConnectedSelector,
   settingsDataSelector,
} from 'admin/state/modules/chat/selectors';
import {
   screenWidthSelector,
   authUserSelector,
   contentMetaInfoSelector,
   siteInfoSelector,
} from 'admin/state/modules/common/selectors';
import {
   fetchNewMessagesOperation,
   fetchLikedMemberByMessageOperation,

} from 'admin/state/modules/chat/operations';
import {
   setChatConversationAction,
   updateConverstionAction,
   updateMessageAction,

} from 'admin/state/modules/chat/actions';
import {
   startUploadOperation,

} from 'admin/state/modules/vault/operations';

import { connect } from 'react-redux';
import CircleLoader from 'common/components/elements/loader/circle';
import Dialogue from "common/components/modules/chat/dialogue"
import { isEmpty as isEmptyObject, keys } from 'lodash'
import SyncPageScoll from 'common/utils/pageScrollSync';
import SelectFromModal from 'admin/views/chat/modules/select-from-modal';
import PhotosetsContainer from 'admin/containers/pages/photosets/list';
import VideosContainer from 'admin/containers/pages/videos/list';
import VaultContainer from 'admin/containers/pages/vault';
import {
   uploadingVideo,
   cancelUplaod,
} from 'admin/utils/VaultVideoUpload';
import {
   deleteVaultFile,
   checkVaultByHash,
   getS3Policy,
   uploadPhotosetsImage,
   getObjectUrlV2,
   createVault,
   checkContentTypeInTheChat,
   getMediaBlurhash,
   checkContentTypeInTheChatWithGroup,
} from 'admin/api/AuthApi'
import QueryParams from 'admin/utils/QueryParams';
import { getVideoFileHash } from 'common/utils/utils'
import axios from 'axios';
import randomstring from 'randomstring';
import SparkMD5 from 'spark-md5';
import { flushSync } from 'react-dom';
import { ARTICLE_ID_ON_CHAT } from 'common/utils/intercom-articles';
import Intercom from 'common/utils/Intercom';
import toast from 'common/utils/toast';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import { clearState as clearPhotosetsState } from 'admin/state/modules/photosets/actions'
import { clearState as clearVideostState } from 'admin/state/modules/videos/actions'
import { clearStateAction as clearVaultsState } from 'admin/state/modules/vault/actions';

class ChatConversationMessagesContainer extends Component {
   filter = {
      page: 1,
   };
   filterMember = {
      page: 1,
   };
   previousY = 0
   static propTypes = {
      isFetchConversationMessages: PropTypes.bool,
      conversationMessages: PropTypes.object,
      authUser: PropTypes.object,
      activeConverstaion: PropTypes.object,
      isNewFetching: PropTypes.bool,
      fetchNewMessages: PropTypes.func,
      fetchLikedMemberByMessage: PropTypes.func,
      likedMemberByMessage: PropTypes.object,
      onUserTyping: PropTypes.func,
      onLikeMessage: PropTypes.func,
      onDeleteMessag: PropTypes.func,
      updateMessageUnlockDetails: PropTypes.func,
      sendMessage: PropTypes.func,
      onUnMuteMember: PropTypes.func,
      onReadMessage: PropTypes.func,
      groupMembersCount: PropTypes.number,
      screenWidth: PropTypes.number,
      fetchMemberByUuid: PropTypes.func,
      memberByUuid: PropTypes.object,
      isSocketConnected: PropTypes.bool,
      settingsData: PropTypes.object,
      goTo: PropTypes.func,
      onClickUnnblockMember: PropTypes.func,
      setChatConversation: PropTypes.func,
      contentMetaInfo: PropTypes.object,
      isMiniChat: PropTypes.bool,
      updateConverstion: PropTypes.func,
      updateMessageData: PropTypes.func,
      updateMessage: PropTypes.func,
      siteInfo: PropTypes.object,
      clearPhotosetsState: PropTypes.func,
      clearVideostState: PropTypes.func,
      clearVaultsState: PropTypes.func,
   }
   currentMedia = {}
   uploadVideoItem = []
   conversationId = (!!Number(QueryParams.getParam('conversation')) ? +QueryParams.getParam('conversation') : QueryParams.getParam('conversation'))
   state = {
      selctFromModal: {
         isOpen: false,
         type: '',
         disabled: true,
         openDiscardModal: false,
      },
      selectedMedia: {},

      selectedAttachments: {
         [this.conversationId]: [],
      },
   }
   timeout = null
   uploadVideoId = null
   source = axios.CancelToken.source();


   componentWillUnmount(){
      window.removeEventListener('content-update', this.bindSocketEvents);
      clearTimeout(this.timeout)
      const { screenWidth } = this.props
      if(screenWidth < 1025){
         SyncPageScoll.syncScroll(false)

      }
   }
   componentDidMount() {
      const { screenWidth, activeConverstaion } = this.props
      if(screenWidth < 1025){
         SyncPageScoll.syncScroll(true)

      }
      this.uploadVideoId = QueryParams.getParam('upload_video')
      window.addEventListener('content-update', this.bindSocketEvents);
      this.onUpdateRecordStatus(null);


      if(window.new_conversations && activeConverstaion.isFake) {
         let currentId = activeConverstaion.chat_room_id

         Object.keys(window.new_conversations).forEach(key => {
            if(activeConverstaion && activeConverstaion.chat_room_id && key.replace('new_') === activeConverstaion.chat_room_id) {
               currentId = activeConverstaion.chat_room_id
            }
         })

         this.conversationId = !!Number(currentId) ? +currentId : currentId
         QueryParams.setQueryParam('conversation', currentId)

      }
      console.log('this.conversationId', this.conversationId);

      if(activeConverstaion.chat_room_id && !this.state.selectedAttachments?.[activeConverstaion.chat_room_id]) {
         this.setState({
            selectedAttachments: {
               ...this.state.selectedAttachments,
               [activeConverstaion.chat_room_id || this.conversationId]: [...activeConverstaion.attachmentsForShow],
            },
         })
      }

   }

   componentDidUpdate(prevProps, prevState) {

      if(prevProps?.activeConverstaion?.chat_room_id !== this.props.activeConverstaion.chat_room_id) {

         this.conversationId = (!!Number(this.props.activeConverstaion.chat_room_id) ? +this.props.activeConverstaion.chat_room_id : this.props.activeConverstaion.chat_room_id) ||  (!!Number(QueryParams.getParam('conversation')) ? +QueryParams.getParam('conversation') : QueryParams.getParam('conversation'))

         if(prevProps.activeConverstaion.chat_room_id && prevProps.activeConverstaion.chat_room_id === prevProps.activeConverstaion.user_uuid) {
            this.setState({
               selectedAttachments: {
                  ...this.state.selectedAttachments,
                  [this.props.activeConverstaion.chat_room_id]: this.props.activeConverstaion.attachmentsForShow,
               },
            })
         }
      }

      if(this.conversationId === null) {
         this.conversationId = this.props.activeConverstaion?.chat_room_id
      }
      if(this.conversationId === 'new' && !!(Number(this.props.activeConverstaion?.chat_room_id))) {
         this.conversationId = this.props.activeConverstaion?.chat_room_id
      }


   }
   generateQuery = () => {
      const query = [];
      Object.keys(this.filter).forEach((key) => {
         if(key === 'count' || key === 'page') {
            query.push(`${ key }=${ this.filter[key] }`);
         } else {
            query.push(`query[]=${ key }=${ this.filter[key] }`)
         }
      })
      return query;
   }

   handleScroll = e => {
      const { scrollTop, scrollHeight, firstElementChild } = e.target
      var currentY = scrollTop;
      if(currentY - 10 < scrollHeight - this.previousY && currentY === 0) {
         this.nextPage(async () => {
            const top = firstElementChild.clientHeight - this.previousY
            e.target.scrollTo(0, top);
         })
      }
      this.previousY = firstElementChild.clientHeight;
   }
   nextPage = async (callback) => {
      const {
         fetchNewMessages,
         isNewFetching,
         activeConverstaion,
         conversationMessages: { current_page: currentPage, last_page: pages },
      } = this.props;
      let query = [];
      if(currentPage < pages && !isNewFetching) {
         this.filter = { ...this.filter, page: currentPage + 1 };
         query = this.generateQuery();
         await fetchNewMessages(query, activeConverstaion.chat_room_id);
         if(callback) {
            callback()
            return
         }
         return
      } else {
         return
      }
   }

   ///
   handleScrollMembers = (e, id) => {
      const { scrollHeight, clientHeight, scrollTop } = e.target
      if(((scrollHeight - scrollTop) - clientHeight < 10)) {
         this.nextPageMembers(id)
      }
   }
   nextPageMembers =  (id) => {
      const {
         fetchLikedMemberByMessage,
         likedMemberByMessage,
         likedMemberByMessage: { current_page: currentPage, last_page: pages },
      } = this.props;
      let query = [];
      if(currentPage < pages && !likedMemberByMessage.fetchingScroll) {
         this.filterMember = { ...this.filterMember, page: currentPage + 1 };
         query = this.generateQuery();
         fetchLikedMemberByMessage(query, id);
      } else {
         return
      }
   }

   removeSelectedContent = (i) => {
      if('number' === typeof i) {
         const { activeConverstaion } = this.props
         let old = activeConverstaion.content
         let data = {
            ...old,
            type: old.type,
            resources: old.resources ? old.resources.filter((_, index) => index !== i) : this.currentMedia.resources.filter((_, index) => index !== i),
            selectedIds: old.selectedIds ? old.selectedIds.filter((_, index) => index !== i) : this.currentMedia.selectedIds.filter((_, index) => index !== i),
            src: old.src ? old.src.filter((_, index) => index !== i) : this.currentMedia.src.filter((_, index) => index !== i),
         }
         if(data.selectedIds.length === 0) {
            this.onUpdateCurentConversation({}, 'remove')
         } else {
            this.onUpdateCurentConversation(data, 'update')
         }
      } else {
         this.onUpdateCurentConversation({}, 'remove')
      }
   }
   selectFileByLiber = (type, e) => {

      const currentConversation = this.conversationId

      if(!(currentConversation in this.state.selectedAttachments)) {
         this.setState({
            selectedAttachments: {
               ...this.state.selectedAttachments,
               [currentConversation]: [],
            },
         })
      }

      if('upload_video' !== type) {
         this.onCloseOpenModal({ isOpen: true, type })
      } else {
         this.onCloseOpenModal({ disabled: true })
         this.uploadVideo(e.target.files)

      }
   }
   bindSocketEvents = e => {
      const { selectedAttachments } = this.state
      if(e){
         const { detail: { payload } } = e
         const video = payload
         const {  socket_emit_type } = video;
         const { activeConverstaion } = this.props

         let currAtts = [...activeConverstaion.attachments]
         let currAttsForShow = [...activeConverstaion.attachmentsForShow]

         if('vault_upload' === socket_emit_type && currAttsForShow.reduce((prev, curr) => {
            if(curr?.type?.includes('video')) {
               prev.push(curr?.id)
            }
            return prev
         }, []).includes(video?.id)) {

            let newShowData = {
               id: video.id,
               type: 'upload_video',
               resources: [video.src],
               full_src: video.video_poster_full_src,
               src: video.src,
               count: video.video_duration,
               percentage: video.video_optimization_percentage,
               status: video.video_optimization_status,
            }

            if(currAtts.find(it => it?.id === video?.id || it?.hash === video?.hash || it?.resource_id === video?.id)) {
               let newAttachmentsData = currAtts.map(el => {
                  if(el?.id === video?.id || el?.hash === video?.hash || el?.resource_id === video?.id) {
                     return {
                        ...newShowData,
                        videoUpload: { ...video },
                     }
                  } else {
                     return el
                  }
               })

               this.updateConversationAttachments(newAttachmentsData)
            } else {

               this.updateConversationAttachments([
                  ...currAtts,
                  {
                     type: 'video_unlock',
                     resources: [video.src],
                     video_duration: video.video_duration,
                     cover_image: video.video_poster,
                     resource_type: 'video_vault',
                     resource_id: video.id,
                     src: video.src,
                  },
               ])

            }

            if(currAttsForShow.find(it => it?.id === video?.id || it?.hash === video?.hash)) {
               let newAttachmentsDataForShow = currAttsForShow.map(el => {
                  if(el?.id === video?.id || el?.hash === video?.hash) {
                     return {
                        ...newShowData,
                        videoUpload: { ...video },
                     }
                  } else {
                     return el
                  }
               })

               this.setState({
                  attachmentsForShow: newAttachmentsDataForShow,
               })

               this.updateConversationAttachments(null, newAttachmentsDataForShow)

            } else {

               this.updateConversationAttachments(null, [
                  ...currAttsForShow,
                  {
                     ...newShowData,
                     videoUpload: { ...video },
                  },
               ])

            }

            const currentConversation = this.conversationId

            if(selectedAttachments[currentConversation].find(it => it?.id === video?.id || it?.hash === video?.hash)) {
               let newSelectedAttachmentsData = selectedAttachments[currentConversation].map(el => {
                  if(el?.id === video?.id || el?.hash === video?.hash) {
                     return {
                        ...newShowData,
                        videoUpload: { ...video },
                     }
                  } else {
                     return el
                  }
               })

               this.setState({
                  selectedAttachments: {
                     ...this.state.selectedAttachments,
                     [currentConversation]: newSelectedAttachmentsData,
                  },
               })
            } else {

               this.setState({
                  selectedAttachments: {
                     ...this.state.selectedAttachments,
                     [currentConversation]: [
                        ...this.state.selectedAttachments[currentConversation],
                        {
                           ...newShowData,
                           videoUpload: { ...video },
                        },
                     ],
                  },
               })
            }
         }
      }
   }
   uploadVideo = async (files) => {
      const uploadFiles = files;
      const loadingData = []


      for(let i = 0; i < uploadFiles.length; i++) {

         try {
            const { selectedAttachments } = this.state
            const { activeConverstaion: { attachments: currAtts, attachmentsForShow: currAttsForShow } } = this.props
            let hash = getVideoFileHash(uploadFiles[i])
            const { data } = await checkVaultByHash('video', [hash]);
            loadingData.push(data)

            const value = data[0] ? data[0][hash] : {}

            if(value){
               let newShowData = {
                  id: value.id,
                  src: value.src,
                  type: value.type,
                  full_src: value.video_poster_full_src,
                  percentage: value.video_optimization_percentage,
                  status: value.video_optimization_status,
                  count: value.video_duration,
                  video_poster: value.video_poster,
                  videoUpload: {
                     ...value,
                     canNotDeleted: true,
                  },
               }

               if(currAtts.find(it => it?.id === value?.id || it?.hash === value?.hash || it?.resource_id === value?.id)) {
                  let newAttachmentsData = currAtts.map(el => {
                     if(el?.id === value?.id || el?.hash === value?.hash || el?.resource_id === value?.id) {
                        return newShowData
                     } else {
                        return el
                     }
                  })

                  this.updateConversationAttachments(newAttachmentsData)


               } else {
                  flushSync(() => {

                     this.updateConversationAttachments([...currAtts, {
                        type: 'video_unlock',
                        resources: [newShowData.src],
                        cover_image: newShowData.video_poster,
                        video_duration: newShowData.count,
                        resource_type: 'video_vault',
                        resource_id: value.id,
                        videoUpload: {
                           ...value,
                           canNotDeleted: true,
                        },
                        src: newShowData.src,
                     }])

                  })

               }

               if(currAttsForShow.find(it => it?.id === value?.id || it?.hash === value?.hash)) {
                  let newAttachmentsDataForShow = currAttsForShow.map(el => {
                     if(el?.id === value?.id || el?.hash === value?.hash) {
                        return newShowData
                     } else {
                        return el
                     }
                  })

                  this.updateConversationAttachments(null, newAttachmentsDataForShow)


               } else {
                  flushSync(() => {

                     this.updateConversationAttachments(null, [...currAttsForShow, newShowData])

                  })
               }

               const currentConversation = this.conversationId

               if(selectedAttachments[currentConversation].find(it => it?.id === value?.id || it?.hash === value?.hash)) {
                  let newSelectedAttachmentsData = selectedAttachments[currentConversation].map(el => {
                     if(el?.id === value?.id || el?.hash === value?.hash) {
                        return newShowData
                     } else {
                        return el
                     }
                  })

                  this.setState({
                     selectedAttachments: {
                        ...this.state.selectedAttachments,
                        [currentConversation]: newSelectedAttachmentsData,
                     },
                  })
               } else {
                  flushSync(() => {
                     this.setState({
                        selectedAttachments: {
                           ...this.state.selectedAttachments,
                           [currentConversation]: [...this.state.selectedAttachments[currentConversation], newShowData],
                        },
                     })
                  })
               }

            } else {
               this.startUploadingVideo(uploadFiles[i], hash)
            }


         } catch (error) {
            console.log(error);
         }
      }

   }

   startUploadingVideo = (file, hash) => {
      if(!QueryParams.getParam('upload_video')) {
      }
      let files = []
      if(file) {
         files = [{ file, hash }]
      }

      uploadingVideo(files, (id, data) => {
         const { selectedAttachments } = this.state
         const { activeConverstaion: { attachments: currAtts, attachmentsForShow: currAttsForShow } } = this.props

         if(!id) {
            const { vault } = data

            this.uploadVideoItem = { ...vault[0] }
         } else {

            this.uploadVideoItem = data

         }

         const checkItem = (item) => {
            if(data?.vault && data?.vault[0]?.id === item.id)  {
               return true
            }
            if(item?.resource_type && item?.resource_type.includes(data.type) && item.id === id)  {
               return true
            }
            if(item.hash && data.hash && item.hash === data.hash)  {
               return true
            }
            return false
         }

         const currentAttachment = currAtts.filter(checkItem)

         if(!!currentAttachment.length) {
            let newAttachmentsData = currAtts.map(el => {
               if(checkItem(el)) {
                  return data?.vault ?
                     {
                        ...data?.vault[0],
                     }
                     :
                     {
                        ...el,
                        ...data,
                     }
               } else {
                  return el
               }
            })

            this.updateConversationAttachments(newAttachmentsData)



         } else {

            flushSync(() => {

               this.updateConversationAttachments([...currAtts, data?.vault ? data.vault[0] : data])

            })


         }

         const checkShowItem = (item) => {
            if(data?.vault && data?.vault[0]?.id === item.id)  {
               return true
            }
            if(item?.resource_type && item?.resource_type.includes(data.type) && item.id === id)  {
               return true
            }
            if(item.hash && data.hash && item.hash === data.hash)  {
               return true
            }
            return false
         }

         const currentShowAttachment = currAttsForShow.filter(checkShowItem)

         if(!!currentShowAttachment.length) {
            let newShowAttachmentsData = currAttsForShow.map(el => {
               if(checkShowItem(el)) {
                  return data?.vault ?
                     {
                        ...data?.vault[0],
                     }
                     :
                     {
                        ...el,
                        ...data,
                     }
               } else {
                  return el
               }
            })

            this.updateConversationAttachments(null, newShowAttachmentsData)


         } else {

            flushSync(() => {

               this.updateConversationAttachments(null, [...currAttsForShow, data?.vault ? data.vault[0] : data])

            })

         }


         const checkSelectedItem = (item) => {
            if(data?.vault && data?.vault[0]?.id === item.id)  {
               return true
            }
            if(item?.type && item?.type.includes(data.type) && item.id === data.id)  {
               return true
            }
            if(item.hash && data.hash && item.hash === data.hash)  {
               return true
            }
            return false
         }

         const currentConversation = this.conversationId

         const currentShowAttachmentRef = selectedAttachments[currentConversation].filter(checkShowItem)

         if(!!currentShowAttachmentRef.length) {
            let newShowAttachmentsData = selectedAttachments[currentConversation].map(el => {
               if(checkSelectedItem(el)) {
                  return data?.vault ?
                     {
                        ...data?.vault[0],
                     }
                     :
                     {
                        ...el,
                        ...data,
                     }
               } else {
                  return el
               }
            })

            this.setState({
               selectedAttachments: {
                  ...this.state.selectedAttachments,
                  [currentConversation]: newShowAttachmentsData },
            })

         } else {

            this.setState({
               selectedAttachments: {
                  ...this.state.selectedAttachments,
                  [currentConversation]: [...this.state.selectedAttachments[currentConversation], data?.vault ? data.vault[0] : data],
               },
            })

         }
         return
      }, () => this.uploadVideoItem,  () => this.startUploadingVideo())
   }

   onCloseOpenModal = data => {
      const { selctFromModal } = this.state
      const {  clearPhotosetsState, clearVideostState, clearVaultsState } = this.props
      if(!data.isOpen && 'video_vault' === selctFromModal.type){
         this.bindSocketEvents()
      }
      this.setState({
         selctFromModal: {
            ...selctFromModal,
            ...data,
            openDiscardModal: false,
         },
      }, () => {
         if(!data.isOpen) {
            const clearStateData = { isInited: false, key: '', filters: {} }
            clearPhotosetsState({ ...clearStateData, photosetsDataInsideModal: {}, filtersInsideModal: {} })
            clearVideostState({ ...clearStateData, videosDataInsideModal: {}, filtersInsideModal: {} })
            clearVaultsState({
               vaultVideosIsInited: false,
               vaultImagesIsInited: false,
               allVaultIsInited: false,
            })
         }
      })
   }


   onUpdateCurentConversation = async (data, action, roomId) => {
      const {
         setChatConversation,
         activeConverstaion,
         activeConverstaion: {
            attachments: currAtts,
            attachmentsForShow: currAttsForShow,
         },
      } = this.props;
      const {
         selectedAttachments,
      } = this.state;
      const id = roomId || activeConverstaion.chat_room_id;

      const currentConversation = this.conversationId

      if(action === 'update') {
         setChatConversation({
            ...activeConverstaion,
            attachments: currAtts,
            attachmentsForShow: currAttsForShow,
            content: {
               ...activeConverstaion.content || {},
               ...data,
            },
         })

      } else {

         if(!!data?.loadingData?.length > 0) {

            let currentAttachments = [...currAtts] //[...attachments]
            let currentAttachmentsForShow = [...currAttsForShow] //[...attachmentsForShow]
            let currentSelectedAttachmentsArr = [...selectedAttachments[currentConversation]]

            data?.loadingData.forEach((file, index) => {

               if(!currAtts.find(it => it?.resource_type?.includes('photo') && it?.hashes && it?.hashes[0] === file?.hash)) {

                  currentAttachments.push({
                     type: 'photo_unlock',
                     resources: [],
                     resource_id: SparkMD5.hash(file?.hash),
                     resource_type: 'photo_vault',
                     hashes: [file?.hash],
                     status: 'uploading',
                  })
               }

               if(!currAttsForShow.find(it => it.type === 'photo' && it?.hashs && it?.hashs[0] === file?.hash)) {

                  currentAttachmentsForShow.push({
                     prevFiles: [],
                     filesArr: [],
                     vaultPhotos: [],
                     type: 'photo',
                     hashs: [file?.hash],
                     status: 'uploading',
                  })
               }

               if(!selectedAttachments[currentConversation].find(it => it.type === 'photo' && it?.hashs && it?.hashs[0] === file?.hash)) {

                  currentSelectedAttachmentsArr.push({
                     prevFiles: [],
                     filesArr: [],
                     vaultPhotos: [],
                     type: 'photo',
                     hashs: [file?.hash],
                     status: 'uploading',
                  })
               }
            })

            this.setState({
               selectedAttachments: {
                  ...this.state.selectedAttachments,
                  [currentConversation]: currentSelectedAttachmentsArr,
               },
            })

            this.updateConversationAttachments(currentAttachments, currentAttachmentsForShow)


         } else if(data?.loadingData?.length === 0) {
            if(!!data?.filesArr?.length) {
               let currentAttachments = [...currAtts] // [...attachments]
               let currentAttachmentsForShow = [...currAttsForShow] // [...attachmentsForShow]
               let currentSelectedAttachmentsArr = [...selectedAttachments[currentConversation]]

               data?.filesArr.forEach((file, index) => {

                  currentAttachments = currentAttachments.map((it, i) => {
                     if(it?.resource_type?.includes('photo') && it?.hashes && it?.hashes[0] === data.hashs[index] && it?.status === 'uploading') {
                        return {
                           type: 'photo_unlock',
                           resources: [data?.filesArr[index]],
                           resource_id: SparkMD5.hash(data?.hashs[index]),
                           resource_type: 'photo_vault',
                           hashes: [data?.hashs[index]],
                        }
                     } else {
                        return it
                     }
                  })

                  currentAttachmentsForShow = currentAttachmentsForShow.map((it, i) => {
                     if(it.type === 'photo' && it?.hashs && it?.hashs[0] === data.hashs[index] && it?.status === 'uploading') {
                        return {
                           prevFiles: [data.prevFiles[index]],
                           filesArr: [data.filesArr[index]],
                           vaultPhotos: !!data.vaultPhotos[index] ?  [data.vaultPhotos[index]] : [],
                           type: 'photo',
                           hashs: [data.prevFiles[index]?.hash],
                        }
                     } else {
                        return it
                     }
                  })

                  currentSelectedAttachmentsArr = currentSelectedAttachmentsArr.map((it, i) => {
                     if(it.type === 'photo' && it?.hashs && it?.hashs[0] === data.hashs[index] && it?.status === 'uploading') {
                        return {
                           prevFiles: [data.prevFiles[index]],
                           filesArr: [data.filesArr[index]],
                           vaultPhotos: !!data.vaultPhotos[index] ?  [data.vaultPhotos[index]] : [],
                           type: 'photo',
                           hashs: [data.prevFiles[index]?.hash],
                        }
                     } else {
                        return it
                     }
                  })
               })

               this.setState({
                  selectedAttachments: {
                     ...this.state.selectedAttachments,
                     [currentConversation]: currentSelectedAttachmentsArr,
                  },
               })

               this.updateConversationAttachments(currentAttachments, currentAttachmentsForShow)

            }

         }

      }
      let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}
      if('add' === action) {
         ollContents = {
            ...ollContents,
            [id]: data,
         }
      }
      if('remove' === action) {
         delete ollContents[id]
      }
      if('update' === action) {
         ollContents[id] = { ...ollContents[id], ...data }
      }

      window.currentConversationContent = JSON.stringify(ollContents)

   }

   onAddMediaItem = data => {

      const { selectedAttachments } = this.state

      const currentConversation = this.conversationId

      let existingAttachments = []
      let existingAttachmentsForShow = []

      selectedAttachments[currentConversation].forEach(element => {
         let shape = {}
         let attachmentData = {}

         console.log({ element });

         switch (element.type) {
            case 'photoset':
               shape = element
               attachmentData = {
                  resource_id: element.id,
                  resource_type: 'photoset',
                  type: element?.type.includes('video') ? 'video_unlock' : 'photo_unlock',
                  resources: element.src,
                  photos_count: element.photos_count || element.count,
                  title: element.title,
                  locked_image_is_blur: element.locked_image_is_blur,
                  publish_date: element.publish_date,
               }
               break;
            case 'video':
            case 'upload_video':
               shape = element
               if(element?.videoUpload) {
                  attachmentData =    {
                     resource_id: element.videoUpload?.id,
                     resource_type: 'video_vault', // 'video',
                     type: 'video_unlock',
                     resources: [element.videoUpload?.src],
                     video_duration: element.videoUpload?.video_duration,
                     cover_image: element.videoUpload?.video_poster || element.videoUpload?.video_poster_full_src,
                     src: element.videoUpload?.src,
                     // title: element.title,
                  }
               }
               else if(element?.uploadingStatus === 'uploading') {
                  attachmentData =   {
                     resource_id: element.id,
                     resource_type: 'video',
                     type: 'video_unlock',
                     resources: null,
                     video_duration: null,
                     cover_image: null,
                     hash: element?.hash,
                  }
               }
               else {
                  attachmentData = {
                     resource_id: element.id,
                     resource_type: element.type === 'video' ? 'video' : 'video_vault', // 'video',
                     type: 'video_unlock',
                     resources: [element.src],
                     video_duration: element.video_duration || element.count,
                     cover_image: element?.cover_image,
                     src: element.src,
                     title: element.title,
                     locked_image_is_blur: element.locked_image_is_blur,
                     publish_date: element.publish_date,
                     basename: element?.basename,
                  }
               }
               break;
            case 'video_vault':
               shape = element
               attachmentData = {
                  resource_id: element.id,
                  resource_type: 'video_vault',
                  type: element?.type.includes('video') ? 'video_unlock' : 'photo_unlock',
                  resources: [element.src],
                  video_duration: element.count,
                  cover_image: element.cover_image || element.full_src,
                  src: element?.src,
               }
               break;
            case 'photo_vault':
               shape = element
               attachmentData = {
                  resource_id: element.id,
                  resource_type: 'photo_vault',
                  type: element?.type.includes('video') ? 'video_unlock' : 'photo_unlock',
                  resources: [element.src],
                  cover_image: element.src,
               }
               break;
            case 'photo':
               shape = element
               attachmentData = {
                  resource_id: SparkMD5.hash(element.hashs[0]),
                  resource_type: 'photo_vault',
                  type: element?.type.includes('video') ? 'video_unlock' : 'photo_unlock',
                  resources: element.filesArr,
                  hashes: element?.hashs,
               }
               break;

            default:
               break;
         }

         existingAttachments.push(attachmentData)

         existingAttachmentsForShow.push(shape)


      });

      this.setState({
         selectedAttachments: {
            ...this.state.selectedAttachments,
            [currentConversation]: [...existingAttachmentsForShow],
         },
      })

      this.updateConversationAttachments(existingAttachments, existingAttachmentsForShow)

      this.onCloseOpenModal({ isOpen: false, type: null, disabled: true })

   }

   getButtonDisabled  =  _ => {
      return !this.currentMedia.resources ||  this.currentMedia.resources.length === 0
   }

   deleteVaultVideo = async id => {
      try {
         await deleteVaultFile(id)
         this.removeSelectedContent()
      } catch (error) {

      }
   }

   removeUploadVideoQuery = () => {
      this.uploadVideoId = null
      QueryParams.removeQueryParam('upload_video')
   }

   getChatPrivateSettingsAccessMode = (userStatus, settings, pricingId) => {
      let result = !!settings && 'off' !== settings[`chat_private_${ userStatus }_members_messaging_mode`];
      switch (userStatus) {
         case 'active':
            if('different' === settings?.chat_private_active_members_access_mode){
               const differentData = settings?.chat_private_active_different_members_payed_messaging_payload_array;
               let currentState = null;
               if(differentData && !!differentData.find){
                  currentState = differentData.find(el => el.id === pricingId)
                  if(currentState) {
                     result = 'off' !== currentState.access?.type;
                  }
               }
            }
            break;
         case 'guest':
         case 'expired':
            if('all' ===  settings?.chat_private_others_members_access_mode){
               result = 'off' !== settings?.chat_private_others_members_messaging_mode;
            }
            break;
         default:
            break;
      }

      return result;
   }

   onSendVoiceMessage = async (file, details, callback, parentMessageId) => {
      const { sendMessage } = this.props;
      const client = axios.create();
      const basename = randomstring.generate(32);
      const ext = 'mp3';
      const filename = `audio-${ basename }.${ ext }`;
      const s3PolicyGet = await getS3Policy();
      const s3Policy = s3PolicyGet.data;
      const key = `audio-recordings/${ filename }`;
      const formData = new FormData();
      formData.append('key', key);
      formData.append('AWSAccessKeyId', s3Policy.accesskey);
      formData.append('acl', 'private');
      formData.append('success_action_status', '201');
      formData.append('policy', s3Policy.policy);
      formData.append('signature', s3Policy.signature);
      formData.append('file', file);
      try {

         await client.post(
            `https://${ s3Policy.bucket }.s3.amazonaws.com`,
            formData,
            {
               // cancelToken: this.source?.token,
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
               onUploadProgress: progress => {
                  // const uploadingProgress = Math.floor(
                  //    (progress.loaded / progress.total) * 100
                  // );
                  // console.log(uploadingProgress, this.source)
               },
            }
         );
         const { activeConverstaion } = this.props;
         try {
            if(callback){
               callback()
               const messageData = {
                  unlockPrice: details.price || 0,
                  lockedPosterIsBlur: null,
                  resources: [filename],
                  type: 'voice_message',
                  resourceId: null,
                  text: '',
                  duration: details.duration,
                  makeDefaultPrice: details.make_default_price_for_voice_messages,
               }
               sendMessage(messageData, parentMessageId, null, () => {
                  if(callback){
                     callback()
                  }
               }, activeConverstaion.chat_room_id)
            }
         } catch (err){
            console.log(err, 1)

         }
      } catch (error) {
         console.log(error, 2)
      }
   }


   onUpdateRecordStatus = (status) => {
      if(status){
         QueryParams.setQueryParam('recorded_voice', status)
      } else if(QueryParams.getParam('recorded_voice')){
         QueryParams.removeQueryParam('recorded_voice');
      }
      window.chatVoiceMessageRecorded = status;
   }

   uploadImage = async (appendData) => {
      try {
         const { data: { path } } =  await uploadPhotosetsImage(appendData);
         const fullSrc =  await getObjectUrlV2({ paths: [path], pathName: 'images/thumb-' });
         return Promise.resolve({ fullSrc, path });
      } catch (error) {
         return Promise.reject(error);
      }
   }
   checkVaultByHash = async (hashs) => {
      try {
         const { data } =  await checkVaultByHash('image', [...hashs]);
         return Promise.resolve(data);
      } catch (error) {
         return Promise.reject(error);
      }
   }
   createVault = async (newVaultPhotos) => {
      try {
         await createVault({ data: newVaultPhotos })
         return Promise.resolve('');
      } catch (error) {
         return Promise.reject(error);
      }
   }
   checkContentTypeInTheChat = async (info) => {
      try {
         let checkData
         if(!info?.is_group_chat) {
            checkData = await checkContentTypeInTheChat(info);
         } else {
            checkData = await checkContentTypeInTheChatWithGroup(info);
         }

         return Promise.resolve(checkData);
      } catch (error) {
         return Promise.reject(error);
      }
   }
   getMediaBlurhash = async (resourcePath, type = 'photoset', videoKey = null) => {
      try {
         let { data: { blurhash } } = await getMediaBlurhash({
            path: resourcePath,
            type,
            videoKey,
         })
         return Promise.resolve(blurhash);
      } catch (error) {
         return Promise.reject(error);
      }
   }

   onRemoveMedia = (media, index, message, callback, bool = false) => {
      const { selectedAttachments } = this.state;
      const { activeConverstaion: { attachments: currAtts, attachmentsForShow: currAttsForShow }, updateMessage, updateMessageData } = this.props;

      const currentConversation = this.conversationId

      if(media?.type === 'video' && ['pending', 'uploading'].includes(media?.uploadingStatus) && media?.uploadInfo && media?.uploadInfo?.uploadPath) {
         if(!!cancelUplaod){
            cancelUplaod(media, (id) => {})
         }
      }

      if(
         (media?.type === 'video' && ['in_optimization_queue'].includes(media?.uploadingStatus)) ||
         (media?.type === 'upload_video' && media?.videoUpload && ['optimizing', 'completed'].includes(media?.status))
      ) {

         const videoId = media?.type === 'video' ? media?.id : media?.videoUpload?.id

         this.deleteVaultVideo(videoId)
      }

      if(!!selectedAttachments[currentConversation]) {
         this.setState({
            selectedAttachments: {
               ...this.state.selectedAttachments,
               [currentConversation]: [...selectedAttachments[currentConversation]].filter((it, i) => i !== index) }
            ,
         })

      }

      this.updateConversationAttachments([...currAtts].filter((it, i) => i !== index), [...currAttsForShow].filter((it, i) => i !== index))

      toast.success(!bool ? 'Media attachment has been removed' : 'Media attachment has been unsent')

      if(message) {

         let updatedMessage = {
            ...message,
            unlock_details: {
               ...message.unlock_details,
               attachments: [...message.unlock_details.attachments].filter((it, i) => i !== index),
            },
         }

         if(message?.attachmentsStatuses) {
            updatedMessage = {
               ...updatedMessage,
               unlock_details: {
                  ...updatedMessage.unlock_details,
                  attachmentsStatuses: [...message.attachmentsStatuses].filter((it, i) => i !== index),
               },
            }
         }

         if(message?.unlock_details?.attachmentsStatuses) {
            updatedMessage = {
               ...updatedMessage,
               unlock_details: {
                  ...updatedMessage.unlock_details,
                  attachmentsStatuses: [...message?.unlock_details?.attachmentsStatuses].filter((it, i) => i !== index),
               },
            }
         }

         updateMessage({ conversationId: currentConversation, messageId: message?.id, message: updatedMessage })

         updateMessageData(!!Number(message.id) ? +message?.id : message.id, message?.text, () => console.log('I am callback'), currentConversation, updatedMessage)

         if(callback) {
            callback(updatedMessage)
         }

      }


   }

   updateConversationAttachments = (atts, attsForShow) => {
      const { updateConverstion } = this.props;

      const currentConversation = this.conversationId

      let updatedConversation = {}

      if(atts) {
         updatedConversation.attachments = atts
      }

      if(attsForShow) {
         updatedConversation.attachmentsForShow = attsForShow
      }

      updateConverstion({ conversationId: currentConversation, data: updatedConversation })
   }

   clearState = () => {
      const currentConversation = this.conversationId

      this.setState({
         selectedAttachments: {
            ...this.state.selectedAttachments,
            [currentConversation]: [],
         },
      })
   }

   onClickCancel = () => {
      const { activeConverstaion: { attachments } } = this.props;


      attachments.forEach((content, i) => {

         if(content?.type === 'video' && ['pending', 'uploading', 'in_optimization_queue'].includes(content?.uploadingStatus) && content?.uploadInfo && content?.uploadInfo?.uploadPath) {
            if(!!cancelUplaod){
               cancelUplaod(content, (id) => this.onRemoveMedia(content, i))
            }
         }

      })


      this.updateConversationAttachments([], [])
      this.clearState()
   }

   checkContentDifference = () => {
      const { selectedAttachments } = this.state
      const { activeConverstaion: { attachmentsForShow: currAttsForShow } } = this.props
      const currentAttachments = currAttsForShow?.map(({ id, type }) => ({ id, type })) ?? []
      const currSelectedAttachments = selectedAttachments?.[this.conversationId]?.map(({ id, type }) => ({ id, type })) ?? []

      return JSON.stringify(currSelectedAttachments) === JSON.stringify(currentAttachments)
   }

   onCloseSelectModal = (needCheck = false) => {
      const { activeConverstaion: { attachmentsForShow: currAttsForShow } } = this.props

      if(needCheck && !this.checkContentDifference()) {
         this.openDiscardModal(true)
         return
      }

      this.setState({
         selectedAttachments: {
            ...this.state.selectedAttachments,
            [this.conversationId]: currAttsForShow,
         },
      })
      this.onCloseOpenModal({ isOpen: false, type: null, disabled: true })
   }

   openDiscardModal = (openDiscardModal = false) => {
      this.setState({
         selctFromModal: {
            ...this.state.selctFromModal,
            openDiscardModal,
         },
      })
   }

   render() {
      const {
         screenWidth,
         conversationMessages,
         isFetchConversationMessages,
         authUser,
         activeConverstaion,
         isNewFetching,
         likedMemberByMessage,
         fetchLikedMemberByMessage,

         onUserTyping,
         onLikeMessage,
         onDeleteMessag,
         sendMessage,

         onUnMuteMember,

         groupMembersCount,
         onReadMessage,

         fetchMemberByUuid,
         memberByUuid,
         isSocketConnected,
         settingsData,
         goTo,
         onClickUnnblockMember,
         contentMetaInfo,
         isMiniChat,
         updateMessageUnlockDetails,
         activeConverstaion: { attachments: currAtts, attachmentsForShow: currAttsForShow },
         siteInfo,
      } =  this.props;
      const { selctFromModal, selectedAttachments } = this.state;

      const currentConversation = this.conversationId

      let maxPrice = contentMetaInfo?.content_unlock_in_chat_max_price ?  Number(contentMetaInfo.content_unlock_in_chat_max_price) : 100;
      if(isFetchConversationMessages) {
         return (
            <div
               style={ screenWidth < 1025 ? {
                  height: 'calc(100% - 48px)',
                  marginTop: '48px',
               } : {} }
               className='m-auto flex flex-col items-center justify-center'>
               <CircleLoader />
               <span className='text-placeholder leading-tight text-base mt-5'>
                  {
                     activeConverstaion.isGroup ? (
                        'Loading group chat'
                     ) : (
                        'Loading private chat'
                     )
                  }
               </span>
            </div>
         )
      }
      let typers = {}
      let lastSeen = null
      let adminsettings = {}
      const { settings } = settingsData
      if(activeConverstaion) {
         typers = activeConverstaion.typers
         const {
            last_seen_of_second_participant,
            last_message,
            unread_messages_count_of_second_participant,
         } = activeConverstaion
         if(last_seen_of_second_participant && last_message.user_uuid === authUser.uuid && unread_messages_count_of_second_participant === 0) {
            lastSeen = last_seen_of_second_participant
         }
      }
      let message = null;
      if(typers && !isEmptyObject(typers)) {
         const objectKeys = keys(typers);
         if(objectKeys.length > 1) {
            message = 'Several people are typing...';
         } else {
            message = `${ typers[objectKeys[0]].nickname || typers[objectKeys[0]].username || typers[objectKeys[0]].screen_name } is typing...`;
         }
      }
      let emptyText = `Type your message to start chatting with ${ groupMembersCount }
      ${ groupMembersCount === 1 ? "member" : "members" }`;
      adminsettings = { type: 'group', active: settings && settings.chat_group_is_active  };
      if(activeConverstaion && activeConverstaion.user_uuid) {
         let userStatus = null;
         let pricingId = null;
         if(activeConverstaion.member) {
            userStatus = 'active';
            pricingId = activeConverstaion.member.pricing_id;
            if(!activeConverstaion.member.subscriber) {
               userStatus = !activeConverstaion.member.ever_subscribed ? 'guest' : 'expired';
            }
         }
         let active = true;
         if(!!userStatus) {
            active = this.getChatPrivateSettingsAccessMode(userStatus, settings, pricingId);
         }
         emptyText = `Type your message to start chatting with ${ activeConverstaion.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username }`;
         adminsettings = {
            type: 'private',
            chatOff: !settings.chat_private_is_active,
            active,
         }
      }

      adminsettings = {
         ...adminsettings,
         chat_auto_translator: settings?.chat_auto_translator,
      }

      const isMaxAttachmentCountReached = selectedAttachments[currentConversation]?.length >= 20

      return (
         <>
            <Dialogue
               messages={ conversationMessages.data }
               authUser={ authUser }
               onScroll={ this.handleScroll }
               isNewFetching={ isNewFetching }
               likesInfo={ likedMemberByMessage }
               onLikedMemberPagination={ this.handleScrollMembers }
               onFetchLikesInfo={ (id) => fetchLikedMemberByMessage(null, id) }
               onUserTyping={ onUserTyping }
               onLikeMessage={ onLikeMessage }
               onDeleteMessag={ onDeleteMessag }
               sendMessage={ (text, parnet, updateMessageId, func, updateMessageType) => {
                  sendMessage(text, parnet, updateMessageId, func, activeConverstaion.chat_room_id, this.clearState, updateMessageType)
               }  }
               converstionInfo={ activeConverstaion || {} }
               isEmpty={ conversationMessages.isEmpty }
               typerText={ message }
               unmuteMember={ onUnMuteMember }
               emptyState={ {
                  button_text: 'More info on chat',
                  action: () => Intercom.showArticle(ARTICLE_ID_ON_CHAT),
                  text: emptyText,
                  avatar: activeConverstaion ? (activeConverstaion.avatars || activeConverstaion.user_avatar) : '',
                  username: Boolean(activeConverstaion) ? activeConverstaion.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username : null,
               } }
               onReadMessage={ () => onReadMessage(activeConverstaion || {}) }
               isMobile={ screenWidth < 1025 }
               lastSeen={ lastSeen }
               onClickUserAvatar={ fetchMemberByUuid }
               memberByUuid={ memberByUuid }
               isAdmin={ true }
               isConnected={ isSocketConnected }
               adminChatSettings={ adminsettings }
               goToChatSettings={ () => {
                  goTo(Router.route('CHAT_SETTINGS').getCompiledPath())
               } }
               goToContentMessage={ id => {
                  goTo(Router.route('CHAT_GET_CONTENT').getCompiledPath({ id }))
               } }
               unblockMember={ onClickUnnblockMember }
               selectFileByLiber={ this.selectFileByLiber }
               removeSelectedContent={ this.removeSelectedContent }
               selectedMedia={ activeConverstaion?.content || {} }
               deleteVaultVideo={ this.deleteVaultVideo }
               onSaveUploadPhotos={ (data, action, id) =>    this.onUpdateCurentConversation(data, action, id) }
               maxPrice={ maxPrice }
               isMiniChat={ isMiniChat }
               onSendVoiceMessage={ this.onSendVoiceMessage }
               defaultVoicePrice={ contentMetaInfo?.content_chat_voice_message_default_price ? Number(contentMetaInfo?.content_chat_voice_message_default_price) : null }
               onUpdateRecordStatus={ this.onUpdateRecordStatus }
               cancelVideoUplaod={ cancelUplaod }
               handleUploadImage={ this.uploadImage }
               checkVaultByHash={ this.checkVaultByHash }
               createVault={ this.createVault }
               checkContentTypeInTheChat={ this.checkContentTypeInTheChat }
               getMediaBlurhash={ this.getMediaBlurhash }
               adminUuid={ authUser.uuid }
               goTo={ goTo }
               attachments={ currAtts }
               attachmentsForShow={ currAttsForShow }
               onRemoveMedia={ this.onRemoveMedia }
               onClickCancel={ this.onClickCancel }
               isDemoMode={ siteInfo?.site_status === 'demo_mode' }
               screenWidth={ screenWidth }
               isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
            />
            {
               selctFromModal.isOpen && (
                  <SelectFromModal
                     closeModal={ _ => {
                        this.onCloseSelectModal(true)
                     } }
                     onClickAdd={ () => {
                        this.onAddMediaItem(this.currentMedia)
                     }  }
                     isMobile={ screenWidth < 1025 }
                     type={ selctFromModal.type }
                     disabled={ selctFromModal.disabled }
                     showNewStyles
                     contentType='chat'
                     childClassName='!pt-0'
                     isThereTabSection
                     onChangeTab={ (type) => {
                        this.setState({
                           selctFromModal: {
                              ...selctFromModal,
                              type,
                           },
                        })
                     } }
                     isMultipleAttachments
                  >
                     {
                        'photoset' === selctFromModal.type && (
                           <PhotosetsContainer
                              isInsideModal
                              onSelectThisPhotoset={ (data) => {
                                 let newData = {
                                    ...data,
                                    type: selctFromModal.type,
                                    src: data.cover_photo,
                                    count: data.photos_count,
                                    resources: data.photos_src,
                                    isBlurPoster: data.locked_image_is_blur,
                                 }
                                 this.currentMedia =  newData

                                 if(selectedAttachments[currentConversation].find(it => it?.id === data?.id && it?.type === 'photoset')) {
                                    let filteredSelection = selectedAttachments[currentConversation].filter(el => el?.id !== data?.id)

                                    this.setState({
                                       selectedAttachments: {
                                          ...this.state.selectedAttachments,
                                          [currentConversation]: filteredSelection,
                                       },
                                    })
                                 } else {
                                    if(isMaxAttachmentCountReached) {
                                       toast.error('20 content attachment limit has been reached', { toastId: selctFromModal.type })
                                       return
                                    }

                                    this.setState({
                                       selectedAttachments: {
                                          ...this.state.selectedAttachments,
                                          [currentConversation]: [...selectedAttachments[currentConversation], {
                                             id: data.id,
                                             status: 'completed',
                                             full_src: data.cover_photo,
                                             src: data.photos_src,
                                             type: 'photoset',
                                             count: data.photos_count,
                                             title: data.title,
                                             locked_image_is_blur: data.locked_image_is_blur,
                                             publish_date: data.publish_date,
                                          }] },
                                    })
                                 }

                                 // this.onCloseOpenModal({ disabled: this.getButtonDisabled() })
                              } }
                              isMultipleSelect
                              conversationId={ activeConverstaion.chat_room_id }
                              onDeleteMessag={ onDeleteMessag }
                              showNewStyles
                              selections={ selectedAttachments[currentConversation].reduce((prev, curr, index) => {
                                 if(curr?.type === 'photoset') {
                                    prev.push(curr?.id)
                                 }
                                 return prev
                              }, []) }
                              contentType='chat'
                              isMultipleAttachments
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                           />
                        )
                     }
                     {
                        'video' === selctFromModal.type && (
                           <VideosContainer
                              isInsideModal
                              onSelectThisVideo={ (data) => {
                                 let newData = {
                                    ...data,
                                    type: selctFromModal.type,
                                    src: [data.poster_src],
                                    resources: [data.basename],
                                    count: data.duration,
                                    isBlurPoster: data.locked_image_is_blur,
                                 }
                                 this.currentMedia =  newData

                                 if(selectedAttachments[currentConversation].find(it => it?.id === data?.id && it?.type === 'video')) {
                                    let filteredSelection = selectedAttachments[currentConversation].filter(el => el?.id !== data?.id)

                                    this.setState({
                                       selectedAttachments: {
                                          ...this.state.selectedAttachments,
                                          [currentConversation]: filteredSelection,
                                       },
                                    })
                                 } else {
                                    if(isMaxAttachmentCountReached) {
                                       toast.error('20 content attachment limit has been reached', { toastId: selctFromModal.type })
                                       return
                                    }

                                    this.setState({
                                       selectedAttachments: {
                                          ...this.state.selectedAttachments,
                                          [currentConversation]: [...selectedAttachments[currentConversation], {
                                             id: data.id,
                                             full_src: data.poster_src,
                                             src: data.poster.split('.')[0] + '.' + data?.extname,
                                             type: 'video',
                                             count: data.duration,
                                             status: 'completed',
                                             title: data.title,
                                             cover_image: data?.poster,
                                             locked_image_is_blur: data.locked_image_is_blur,
                                             publish_date: data.publish_date,
                                             basename: data?.basename,
                                          }] },
                                    })
                                 }


                                 // this.onCloseOpenModal({ disabled: this.getButtonDisabled() })
                              } }
                              isMultipleSelect
                              conversationId={ activeConverstaion.chat_room_id }
                              onDeleteMessag={ onDeleteMessag }
                              showNewStyles
                              selections={ selectedAttachments[currentConversation].reduce((prev, curr, index) => {
                                 if(curr?.type === 'video') {
                                    prev.push(curr?.id)
                                 }
                                 return prev
                              }, []) }
                              contentType='chat'
                              isMultipleAttachments
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                           />
                        )
                     }
                     {
                        ['video_vault'].includes(selctFromModal.type) && (
                           <VaultContainer
                              conversationId={ activeConverstaion.chat_room_id }
                              onDeleteMessag={ onDeleteMessag }
                              isInsideModal
                              handleSelectCard={ (data) => {
                                 let result = data.reduce((acc, value) => {
                                    if(value) {
                                       acc.resources.push(value.src)
                                       acc.fullSrc.push(value.image_thumb_src)
                                       acc.selectedIds.push(value.id)

                                    }
                                    return acc
                                 }, { resources: [], fullSrc: [], selectedIds: [] })
                                 let newData = {
                                    type: selctFromModal.type,
                                    src: result.fullSrc,
                                    resources: data,
                                    selectedIds: result.selectedIds,
                                    isBlurPoster: true,
                                 }
                                 if('video_vault' === selctFromModal.type) {
                                    newData.duration = data[0].video_duration || 1200
                                    newData.poster = data[0].video_poster
                                    newData.src = [data[0].video_poster_full_src]
                                    newData.count =  data[0].video_duration || 1200
                                    newData.id =  data[0].id
                                 } else {
                                    newData.count = data.length
                                 }
                                 this.currentMedia =  newData

                                 data.forEach((item, index) => {

                                    if(selectedAttachments[currentConversation].find(it => it?.id === item?.id && it?.type?.includes('video'))) {
                                       let filteredSelection = selectedAttachments[currentConversation].filter(el => el?.id !== item?.id)

                                       this.setState({
                                          selectedAttachments: {
                                             ...this.state.selectedAttachments,
                                             [currentConversation]: filteredSelection,
                                          },
                                       })
                                    } else {
                                       if(isMaxAttachmentCountReached) {
                                          toast.error('20 content attachment limit has been reached', { toastId: selctFromModal.type })
                                          return
                                       }

                                       this.setState({
                                          selectedAttachments: {
                                             ...this.state.selectedAttachments,
                                             [currentConversation]: [...selectedAttachments[currentConversation], {
                                                id: item.id,
                                                full_src: item.video_poster_full_src,
                                                src: item.src,
                                                type: 'video_vault',
                                                count: item.video_duration,
                                                status: item.video_optimization_status,
                                                cover_image: item?.video_poster,
                                             }] },
                                       })
                                    }
                                 })

                                 // this.onCloseOpenModal({ disabled: this.getButtonDisabled() })
                              } }
                              pageType={ 'video' }
                              type={ 'video' }
                              // pageType={ 'all_vault' }
                              // type={ 'all_vault' }
                              contentType='chat'
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                              showNewStyles
                              isMultipleSelect
                              isMultipleAttachments
                              selected={ selectedAttachments[currentConversation].reduce((prev, curr, index) => {
                                 if(curr?.type.includes('video')) {
                                    prev.push(curr?.id)
                                 }
                                 return prev
                              }, []) }
                              isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                           />
                        )
                     }
                     {
                        ['photo_vault'].includes(selctFromModal.type) && (
                           <VaultContainer
                              conversationId={ activeConverstaion.chat_room_id }
                              onDeleteMessag={ onDeleteMessag }
                              isInsideModal
                              handleSelectCard={ (data) => {
                                 let result = data.reduce((acc, value) => {
                                    if(value) {
                                       acc.resources.push(value.src)
                                       acc.fullSrc.push(value.image_thumb_src)
                                       acc.selectedIds.push(value.id)

                                    }
                                    return acc
                                 }, { resources: [], fullSrc: [], selectedIds: [] })
                                 let newData = {
                                    type: selctFromModal.type,
                                    src: result.fullSrc,
                                    resources: data,
                                    selectedIds: result.selectedIds,
                                    isBlurPoster: true,
                                 }
                                 if('video_vault' === selctFromModal.type) {
                                    newData.duration = data[0].video_duration || 1200
                                    newData.poster = data[0].video_poster
                                    newData.src = [data[0].video_poster_full_src]
                                    newData.count =  data[0].video_duration || 1200
                                    newData.id =  data[0].id
                                 } else {
                                    newData.count = data.length
                                 }
                                 this.currentMedia =  newData

                                 data.forEach((item, index) => {
                                    if(selectedAttachments[currentConversation].find(it => it?.id === item?.id && it?.type === 'photo_vault')) {
                                       let filteredSelection = selectedAttachments[currentConversation].filter(el => el?.id !== item?.id)

                                       this.setState({
                                          selectedAttachments: {
                                             ...this.state.selectedAttachments,
                                             [currentConversation]: filteredSelection,
                                          },
                                       })
                                    } else {
                                       if(isMaxAttachmentCountReached) {
                                          toast.error('20 content attachment limit has been reached', { toastId: selctFromModal.type })
                                          return
                                       }

                                       this.setState({
                                          selectedAttachments: {
                                             ...this.state.selectedAttachments,
                                             [currentConversation]: [...selectedAttachments[currentConversation], {
                                                id: item.id,
                                                full_src: item.image_thumb_src,
                                                src: item.src,
                                                type: 'photo_vault',
                                                status: 'completed',
                                             // count: item.duration,
                                             }] },
                                       })
                                    }

                                 })

                                 // this.onCloseOpenModal({ disabled: this.getButtonDisabled() })
                              } }
                              pageType={ 'image' }
                              type={ 'image' }
                              // pageType={ 'all_vault' }
                              // type={ 'all_vault' }
                              contentType='chat'
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                              showNewStyles
                              isMultipleSelect
                              selected={ selectedAttachments[currentConversation].reduce((prev, curr, index) => {
                                 if(curr?.type === 'photo_vault') {
                                    prev.push(curr?.id)
                                 }
                                 return prev
                              }, [])
                              }
                              isMultipleAttachments
                              isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                           />
                        )
                     }
                  </SelectFromModal>

               )
            }
            {
               !!selctFromModal?.openDiscardModal &&
               <ConfirmModal
                  type='confirm'
                  icon='cancel'
                  iconColor='error'
                  descriptionColorClass='text-secondary'
                  onCloseModal={ () => {
                     // setOpenDiscardModal(false)
                     this.openDiscardModal(false)
                  }  }
                  title='Discard changes?'
                  description='Changes you made so far will not be saved'
                  cancelText='Keep editing'
                  nonPrimaryColor
                  contentWidth='375px'
                  isMobile={ screenWidth < 1025 }
                  iconSize='2xl'
                  buttonText='Discard changes'
                  textColor='error'
                  action={ () => {
                     this.onCloseSelectModal()
                  } }
                  buttonClassName='mt-8'
                  modalClassName='!z-[100]'
               />
            }
         </>
      )
   }
}

const mapDispatchToProps = (dispatch) => ({
   fetchNewMessages: (query, id) => dispatch(fetchNewMessagesOperation(query, id)),
   fetchLikedMemberByMessage: (query, id) => dispatch(fetchLikedMemberByMessageOperation(query, id)),
   goTo: location => {
      dispatch(push(location))
   },
   startUpload: (files) => {
      dispatch(startUploadOperation(files))
   },
   setChatConversation: (files) => {
      dispatch(setChatConversationAction(files))
   },
   updateConverstion: data => dispatch(updateConverstionAction(data)),
   updateMessage: data => dispatch(updateMessageAction(data)),
   clearPhotosetsState: data => dispatch(clearPhotosetsState(data)),
   clearVideostState: data => dispatch(clearVideostState(data)),
   clearVaultsState: data => dispatch(clearVaultsState(data)),
})

const mapStatesToProps = state => ({
   screenWidth: screenWidthSelector(state),
   isFetchConversationMessages: isFetchConversationMessagesSelector(state),
   conversationMessages: conversationMessagesSelector(state),
   isEmpty: isEmptyConversationMessagesSelector(state),
   authUser: authUserSelector(state),
   activeConverstaion: activeConverstaionSelector(state),
   isNewFetching: isFetchNewConversationMessagesSelector(state),
   likedMemberByMessage: likedMemberByMessageSelector(state),
   isSocketConnected: isSocketConnectedSelector(state),
   settingsData: settingsDataSelector(state),
   contentMetaInfo: contentMetaInfoSelector(state),
   siteInfo: siteInfoSelector(state),

});

export default connect(mapStatesToProps, mapDispatchToProps)(ChatConversationMessagesContainer);
