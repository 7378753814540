import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import DateFormatter from 'common/utils/DateFormatter';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import { useSelector } from 'react-redux';
import { upperCaseFirstLetter } from 'common/utils/utils';
import toast from 'common/utils/toast';
import md5 from 'md5';
import classNames from 'classnames';
import { checkContentTypeInTheChat } from 'admin/api/AuthApi';

const ChatMessageContentState = ({
   chatMessages,
   onDeleteMessag,
   updateMessage,
   contentType = 'vault_video',
   path,
   updateMessageUnlockDetails,
   hash,
   onClickPreview,
   resourceId,
   isLoading,
}) => {
   const screenWidth = useSelector(state => state.common.screenWidth);
   const [openConfirmModal, setOpenConfirmModal] = useState(false);
   const isMobile = screenWidth < 1025;
   const chatMessage = chatMessages?.find(el => el.status === 'unlocked') || chatMessages[0];
   const { activeConverstaion } = useSelector(state => state.chat)


   const updateChatPhotos = (message, bool = false) => {
      let newCover = message.cover;
      const hashs = message.hashs.filter(e => e !== hash)
      const paths = message.paths.filter(el => el !== path);
      if(message.cover === path){
         newCover = paths[0];
      }
      const implodedHashs = hashs.join('');
      const unlockDetails = {
         ...message.unlock_details,
         resource_id: md5(implodedHashs),
         cover: newCover,
         photos_count: paths.length,
      }
      const messagesData = {
         unlock_details: unlockDetails,
         paths,
         hashs,
      }
      let data = {
         paths: paths,
         deletedResources: [path],
         unlockDetails: unlockDetails,
      }

      updateMessageUnlockDetails(message.id, () => {
         updateMessage(message.id, false, message.resource_type, messagesData)
         setOpenConfirmModal(false);
         if(bool){
            const tooltipText = `${ upperCaseFirstLetter(getContentTypeTooltipText()) } has been unsent`;
            toast.remove(tooltipText, isMobile ? { onOpen: () => {
               const toasterContainer = document.getElementsByClassName('ams-toast-container')[0];
               if(toasterContainer) {
                  toasterContainer.style.top = `60px`;
               }

            } } : {});
         }
      }, message.conversation_id, data)
   }

   const updateMultipleAttachmentMessage = async (message, bool = false, cb, isDeleteAll = false) => {

      let data = {
         unlockDetails: { ...message?.unlock_details },
         paths: [],
         contentType,
      }

      let messageData = null

      const attachmentsStatus = []
      const { attachments } = message?.unlock_details

      if(attachments) {
         attachments.forEach(async (ctnt) => {

            try {
               let info = {
                  // is_group_chat: !!props.isGroup,
                  is_group_chat: false,
               }

               if(!activeConverstaion?.isGroup){
                  info = {
                     ...info,
                     price: +message?.unlock_details?.unlock_price,
                     member_uuid: activeConverstaion?.user_uuid,
                  }
               }

               if("photo_vault" === ctnt?.resource_type) {
                  info = {
                     ...info,
                     type: ctnt?.resource_type,
                     paths: ctnt?.resources,
                     hashs: ctnt?.hashes || [],
                  }

               }

               if("photoset" === ctnt?.resource_type) {
                  info = {
                     ...info,
                     type: ctnt?.resource_type,
                     resource_id: ctnt?.resource_id,
                  }
               }


               if("video" === ctnt?.resource_type) {
                  info = {
                     ...info,
                     type: ctnt?.title ? ctnt?.resource_type : 'video_vault',
                     resource_id: ctnt?.resource_id,
                  }
               }


               if("video_vault" === ctnt?.resource_type) {
                  info = {
                     ...info,
                     type: ctnt?.resource_type,
                     resource_id: ctnt?.resource_id,
                  }
               }


               attachmentsStatus.push(checkContentTypeInTheChat(info))

            } catch (error) {
               // setSendMediaDisabled(false)
               // setIsLoadingSendBtn(false)
               toast.error('Something went wrong');
            }

         })

         data.unlockDetails.attachmentsStatuses = (await Promise.allSettled(attachmentsStatus)).map(it => it.value.data)
      }


      if((contentType === 'photoset' || contentType === 'video') && !!Number(resourceId)) {
         data.unlockDetails = {
            ...data.unlockDetails,
            attachments: data.unlockDetails.attachments.filter((el, index) => {
               if((el.resource_type && el.resource_type === contentType) && (el.resource_id && el.resource_id === resourceId)) {
                  data.deletedResources = el?.resources
                  data.indexOfDeletedMedia = index
                  // if(data.unlockDetails.attachmentsStatuses) {
                  //    data.unlockDetails.attachmentsStatuses = [...data.unlockDetails.attachmentsStatuses].filter((it, i) => i !== index)
                  // }
                  return false
               } else {
                  return true
               }
            }),
         }
      }

      if((contentType === 'vault_image') && !!Number(resourceId)) {
         data.unlockDetails = {
            ...data.unlockDetails,
            attachments: data.unlockDetails.attachments.filter((el, index) => {
               if(
                  (el.resource_type && el.resource_type === "photo_vault") &&
                  (el.resource_id && ((!!Number(el.resource_id) && el.resource_id === resourceId) || (el.hashes && hash && el.hashes[0] === hash)))
               ) {
                  data.deletedResources = el?.resources
                  data.indexOfDeletedMedia = index
                  // if(data.unlockDetails.attachmentsStatuses) {
                  //    data.unlockDetails.attachmentsStatuses = [...data.unlockDetails.attachmentsStatuses].filter((it, i) => i !== index)
                  // }
                  return false
               } else {
                  return true
               }
            }),
         }
      }
      if((contentType === 'vault_video') && !!Number(resourceId)) {
         data.unlockDetails = {
            ...data.unlockDetails,
            attachments: data.unlockDetails.attachments.filter((el, index) => {
               if((el.resource_type && el.resource_type === "video_vault") && (el.resource_id && el.resource_id === resourceId)) {
                  data.deletedResources = el?.resources
                  data.indexOfDeletedMedia = index
                  // if(data.unlockDetails.attachmentsStatuses) {
                  //    data.unlockDetails.attachmentsStatuses = [...data.unlockDetails.attachmentsStatuses].filter((it, i) => i !== index)
                  // }
                  return false
               } else {
                  return true
               }
            }),
         }
      }

      data.paths = data.unlockDetails.attachments.reduce((prev, curr) => {
         if(curr?.resources && curr.resources.length > 0) {
            prev = [...prev, ...curr.resources]
         }
         return prev
      }, [])

      if(data.unlockDetails.attachments.length > 0 || !!message?.text) {

         if(contentType === 'vault_image') {
            messageData = {
               unlock_details: data.unlockDetails,
               paths: message.paths.filter(el => el !== path),
               hashs: message.hashs.filter(e => e !== hash),
            }
         }

         updateMessageUnlockDetails(message.id, () => {
            updateMessage(message.id, false, message.resource_type, messageData, contentType)
            setOpenConfirmModal(false);
            if(!isDeleteAll){
               const tooltipText = `${ upperCaseFirstLetter(getContentTypeTooltipText()) } has been unsent`;
               toast.remove(tooltipText, isMobile ? { onOpen: () => {
                  const toasterContainer = document.getElementsByClassName('ams-toast-container')[0];
                  if(toasterContainer) {
                     toasterContainer.style.top = `60px`;
                  }

               } } : {});
            }
         }, message.conversation_id, data)

      } else {

         const tooltipText = `${ upperCaseFirstLetter(getContentTypeTooltipText()) } has been unsent`;

         onDeleteMessag(message.id, () => {
            updateMessage(message.id, false, message.resource_type, null, contentType)
            setOpenConfirmModal(false);
         }, !isDeleteAll ? tooltipText : false)
      }

   }

   const getContentType = () => {
      if(contentType){
         return contentType.replace('_', ' ');
      }
      return ''
   }
   const getContentTypeTooltipText = () => {
      if(contentType){
         return contentType.replace('vault_', '');
      }
      return ''
   }

   return (
      <>
         {
            openConfirmModal && (
               <CreateEditModal
                  type='confirm'
                  icon='cancel'
                  iconColor='error'
                  descriptionColorClass='text-secondary'
                  action={ () => {
                     if('multiple_attachment' === chatMessage.resource_type || 'multiple_unlock' === chatMessage.resource_type){
                        updateMultipleAttachmentMessage(chatMessage, true, () => updateMessage(chatMessage.id, false, chatMessage.resource_type, null, contentType), false);
                        return
                     }
                     if('photo_vault' === chatMessage.resource_type && chatMessage?.paths && chatMessage.paths.length > 1){
                        updateChatPhotos(chatMessage, true);
                        return
                     }
                     const tooltipText = `${ upperCaseFirstLetter(getContentTypeTooltipText()) } has been unsent`;
                     onDeleteMessag(chatMessage.id, () => {
                        updateMessage(chatMessage.id, false, chatMessage.resource_type)
                        setOpenConfirmModal(false);
                     }, tooltipText)
                  } }
                  onCloseModal={ () => setOpenConfirmModal(false) }
                  title={ `Unsend ${ getContentType() }?` }
                  description='If you unsend this content, it will no longer appear in your chat'
                  cancelText='Cancel'
                  buttonText={ chatMessages?.length > 1 ? 'Unsend last message' : 'Yes, unsend' }
                  nonPrimaryColor
                  textColor='error'
                  contentWidth='375px'
                  isMobile={ isMobile }
                  buttonClassName='mt-8 text-error'
                  iconSize='2xl'
                  isCloseAction={ true }
                  secondButtonTitle={ chatMessages?.length > 1 ? `Unsend all (${ chatMessages?.length } messages)` : null }
                  secondButtonAction={ () => {
                     for(let index = 0; index < chatMessages.length; index++) {
                        const message = chatMessages[index];
                        if('photo_vault' === message.resource_type && message?.paths && message.paths.length > 1){
                           updateChatPhotos(message);
                           continue;
                        }
                        if('multiple_attachment' === message.resource_type || 'multiple_unlock' === message.resource_type){
                           updateMultipleAttachmentMessage(message, false, null, true);
                           continue;
                        } else {
                           onDeleteMessag(message.id, null, null, false);
                        }
                     }
                     const tooltipText = `${ upperCaseFirstLetter(getContentTypeTooltipText()) }s have been unsent`;
                     toast.info(tooltipText);
                     updateMessage(null, true, chatMessage.resource_type, null, contentType);
                  } }
                  isStopPropagation
               />

            )
         }
         <div
            className='bg-major-60 dark:bg-grey-hover-dark-60 flex-col absolute top-0 left-0 w-full rounded-lg h-full p-2 flex items-center justify-center z-[3]'>
            <div className='flex flex-col items-center justify-start h-[109px] gap-2'>
               {
                  'sent' === chatMessage?.status && (
                     <span className='text-pending font-semibold text-base leading-[24px]'>Sent</span>
                  )
               }
               {
                  'unlocked' === chatMessage.status && (
                     <div className='flex gap-1 items-center'>
                        <div className='flex w-5 h-5 items-center justify-center'>
                           <Icon name='unlocked' color='success' size='xl' />
                        </div>
                        <span className='text-success font-semibold text-base leading-[24px]'>Unlocked</span>
                     </div>
                  )
               }

               <div className='flex flex-col gap-1 items-center'>
                  {
                     Boolean(chatMessage?.price) && (
                        <span className='panel-description text-divider font-normal'>{chatMessage.price} USD</span>
                     )
                  }
                  <span className='panel-description text-divider font-normal'>{DateFormatter.convertToUtc(chatMessage.sent_at, 'MMM DD, YYYY')}</span>
               </div>
               <div
                  className={ classNames({
                     'flex flex-col gap-2': true,
                     '!flex-row': 'sent' === chatMessage?.status,
                  }) }
               >
                  {
                     'sent' === chatMessage?.status  && (
                        <div className='h-9 z-[4]'>
                           <Button
                              text='Unsend'
                              textSize='small'
                              textColor='divider'
                              backgroundColor='transparent'
                              classNames='h-full border border-grey-main'
                              borderRadius='large'
                              textMarginX='0'
                              padding='0 px-[14px] py-2'
                              onClick={ (e) => {
                                 e.preventDefault();
                                 e.stopPropagation();
                                 setOpenConfirmModal(true);
                              } }
                           />
                        </div>
                     )
                  }
                  <Button
                     iconName='play-default'
                     textColor='divider'
                     backgroundColor='transparent'
                     classNames='!w-fit border border-grey-main '
                     borderRadius='large'
                     padding='0 px-2 p-[7px]'
                     onClick={ (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClickPreview();
                     } }
                     dataToolTipContent='Preview'
                     isLoading={ isLoading }
                     loaderClassName='!border-2 !w-4 !h-4'
                  />
               </div>
            </div>
         </div>
      </>
   )
}


ChatMessageContentState.propTypes = {
   chatMessages: PropTypes.object,
   updateMessage: PropTypes.func,
   onDeleteMessag: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   contentType: PropTypes.string,
   path: PropTypes.string,
   hash: PropTypes.string,
   onClickPreview: PropTypes.func,
   resourceId: PropTypes.number,
   isLoading: PropTypes.bool,
}

ChatMessageContentState.defaultProps = {
   isLoading: false,
}


export default ChatMessageContentState;
