import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import md5 from 'md5';
import './style.scss';
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';


const ImgGallery = ({
   images,
   onClickFullScreen,
   showFullScreen,
   classNameIconDiv,
   triggerImageLoad,
   startIndex,
   showPlayButton,
   infinite,
   openModalInsteadOfFullScreen,
   disableKeyDown,
   showBullets,
}) => {

   let numClicks = 0;
   let singleClickTimer = 0;
   const galleryRef = useRef(null)
   const [isFullScreen, setIsFullScreen] = useState(false)

   const closeButton = (
      <Button
         backgroundColor='transparent'
         borderRadius='large'
         onClick={ () => galleryRef.current.toggleFullScreen() }
         classNames='!p-3 !w-fit mx-auto !absolute right-1 top-1 z-50'
         textMarginX='0'
         fontIconColor={ 'secondary' }
         darkIconColor='secondary-dark'
         iconName='close'
         iconSize='extraLarge                                                                                 '
      />
   )

   const handleImageLoad = (event) => {
      const src = event.target.src;
      const id = md5(src);
      if(document.getElementById(id) && !triggerImageLoad) {
         return;
      }
      const parentElement = event.target.parentElement;
      const newElement = document.createElement("div");
      newElement.id = id;
      newElement.style.backgroundImage = `url(${ event.target.src })`;
      newElement.style.width = '100%';
      newElement.style.height = '100%';
      newElement.style.filter = 'blur(30px)';
      newElement.style.backgroundRepeat = 'no-repeat';
      newElement.style.backgroundPosition = 'center';
      newElement.style.backgroundSize = 'cover';
      parentElement.insertBefore(newElement, event.target);
   };

   const toggleBodyClass = (add) => {
      if(add) {
         document.body.classList.add('photosets-fullscreen');
      } else {
         document.body.classList.remove('photosets-fullscreen');
      }
   };
   useEffect(() => {
      return () => {
         toggleBodyClass(false);
      };
   }, []);

   const handleFullscreenChange = (isFullScreenMode) => {
      console.log({ isFullScreenMode });
      setIsFullScreen(isFullScreenMode)
      if(isFullScreenMode){
         onClickFullScreen()
      }
      toggleBodyClass(isFullScreenMode)
   };

   let rightNewState = classNameIconDiv ? {
      renderRightNav: (onClick, disabled) => (
         <button
            type='button'
            className='image-gallery-right-nav !filter-none image-gallery-icon z-40'
            onClick={ (e) => {
               e.stopPropagation()
               e.preventDefault()
               e.stopPropagation()
               if(!disabled) {
                  onClick()
               }
            }
            }
            aria-label='Next Slide'
         >
            <div className={ `${ classNameIconDiv } next-icon-classes ${ disabled ? '!hidden' : '' }` }>
               <Icon
                  name='arrow-right'
                  size='[20px]'
                  color='major-dark'
               />
            </div>
         </button>
      ),

   } : ''
   let leftNewState = classNameIconDiv ? {
      renderLeftNav: (onClick, disabled) => (
         <button
            type='button'
            className='image-gallery-left-nav px-4 !filter-none image-gallery-icon z-40'
            onClick={ (e) => {
               e.preventDefault()
               e.stopPropagation()
               if(!disabled) {
                  onClick()
                  if(showPlayButton){
                     galleryRef.current.pause()
                  }
               }
            } }
            aria-label='Previous Slide'
         >
            <div className={ `${ classNameIconDiv } next-icon-classes ${ disabled ? '!hidden' : '' }` }>
               <Icon
                  name='arrow-left'
                  size='[20px]'
                  color='major-dark'
               />
            </div>
         </button>
      ),

   } : ''

   const fullscreenButton = {
      renderFullscreenButton: (onClick, isFullscreen) => (
         <button
            className='image-gallery-fullscreen-button absolute'
            onClick={ () => {
               if(!!openModalInsteadOfFullScreen) {
                  openModalInsteadOfFullScreen(galleryRef.current.getCurrentIndex())
                  return
               }
               onClick()
            } }
         >
            <Icon
               name={ isFullScreen ? 'fullscreen-exit' : 'full-screen' }
               size='3xl'
            />
         </button>
      ),
   }

   const renderPlayPauseButton = {
      renderPlayPauseButton: (onClick, isPlaying) => (
         <button
            onClick={ onClick }
            isPlaying={ isPlaying }
            className='absolute bottom-0 left-0 image-gallery-play-pause-button'
         >
            <Icon
               name={ isPlaying ? 'pause' : 'play-default' }
               size='3xl'
            />
         </button>
      ),
   }

   return (
      <>
         <ImageGallery
            ref={ galleryRef }
            items={ images }
            onImageLoad={ handleImageLoad }
            onScreenChange={ handleFullscreenChange }
            infinite={ infinite }
            showBullets={ showBullets && images?.length > 1 }
            showFullscreenButton={ showFullScreen }
            showPlayButton={ showPlayButton }
            showThumbnails={ false }
            disableKeyDown={ disableKeyDown }
            showNav={ true }
            slideDuration={ 120 }
            additionalClass='app-image-gallery'
            useWindowKeyDown={ true }
            lazyLoad={ true }
            useBrowserFullscreen={ false }
            onClick={ (e) => {
               if(!!openModalInsteadOfFullScreen) {
                  openModalInsteadOfFullScreen(galleryRef.current.getCurrentIndex())
                  return
               }
               numClicks++;
               if(numClicks === 1) {
                  singleClickTimer = setTimeout(() => {
                     numClicks = 0;
                  }, 400);
               } else if(numClicks === 2) {
                  if(showFullScreen) {

                     clearTimeout(singleClickTimer);
                     numClicks = 0;
                     if(galleryRef && galleryRef.current) {
                        galleryRef.current.toggleFullScreen();
                     }
                  }
               }
            } }
            { ...rightNewState }
            { ...leftNewState }
            { ...fullscreenButton }
            { ...renderPlayPauseButton }
            renderCustomControls={ () => !!isFullScreen && closeButton }
            startIndex={ startIndex }
         />

      </>
   )
}

ImgGallery.defaultProps = {
   onClickFullScreen: () => {},
   showFullScreen: true,
   startIndex: 0,
   showPlayButton: false,
   infinite: true,
   openModalInsteadOfFullScreen: false,
   disableKeyDown: true,
   showBullets: false,
};

ImgGallery.propTypes = {
   images: PropTypes.array,
   onClickFullScreen: PropTypes.func,
   showFullScreen: PropTypes.bool,
   classNameIconDiv: PropTypes.string,
   triggerImageLoad: PropTypes.bool,
   startIndex: PropTypes.number,
   infinite: PropTypes.bool,
   showPlayButton: PropTypes.bool,
   openModalInsteadOfFullScreen: PropTypes.func,
   disableKeyDown: PropTypes.bool,
   showBullets: PropTypes.bool,
};

export default React.memo(ImgGallery);

