import React, { Component } from 'react';
import AddNewPhotoset from 'admin/views/photosets/add-new-photoset';
import PropTypes from 'prop-types';
import ResponsiveModal from 'common/components/modules/responsive-modal'
import toast from "common/utils/toast";
import '../style.scss'
import ConfirmModal from 'common/components/modules/modal-wrapper';
import cx from 'classnames'
class PhotosetCreateContainer extends Component {
   static propTypes = {
      photos: PropTypes.array,
      cover: PropTypes.any,
      selectCoverPhoto: PropTypes.func,
      photosLength: PropTypes.number,
      onFileChange: PropTypes.func,
      onDeleteImage: PropTypes.func,
      onCloseModal: PropTypes.func,
      onCloseRedirect: PropTypes.func,
      onContinue: PropTypes.func,
      photpsModalButton: PropTypes.bool,
      onSortEnd: PropTypes.func,
      height: PropTypes.number,
      isEditebl: PropTypes.bool,
      isButtonLoading: PropTypes.bool,
      lockedImageIsBlur: PropTypes.bool,
      onClickUpdate: PropTypes.func,
      withoutModalWraper: PropTypes.bool,
      screenWidth: PropTypes.number,
      isEditPage: PropTypes.bool,
   }
   setActiveCard = id => {

   };
   state = {
      isLength: false,
      isOpenConfirmModal: false,
   }

   deleteItem = null

   componentDidMount(){
      const { photos } = this.props;
      this.setState({ isLength: !!photos.length })
   }

   openRemoveModal = (photo, bool) => {
      const { photos, photpsModalButton } = this.props;
      if(!photpsModalButton && photos.length === 1) {
         toast.error('Photoset must contain at least 1 photo')
      } else {
         this.setState({ isOpenConfirmModal: bool }, () => {
            this.deleteItem = photo
         })
      }
   }

   render() {
      const {
         photos,
         cover,
         selectCoverPhoto,
         photosLength,
         onFileChange,
         onDeleteImage,
         onCloseModal,
         onContinue,
         photpsModalButton,
         onSortEnd,
         onCloseRedirect,
         height,
         isEditebl = true,
         isButtonLoading,

         lockedImageIsBlur,
         onClickUpdate,
         withoutModalWraper,
         screenWidth,
         isEditPage,
      } = this.props

      const { isOpenConfirmModal } = this.state
      const isLoading = photos.find(i => 'loading' === i.status)

      const view = (
         <AddNewPhotoset
            photosCount={ photosLength }
            photos={ photos }
            cover={ cover }
            setActiveCard={ (id) => { this.setActiveCard(id) } }
            fileUpload={ onFileChange }
            removePhoto={ (photo) => this.openRemoveModal(photo, true) }
            isEditebl={ true }
            makeCover={ selectCoverPhoto }
            onSortPhotosEnd={ onSortEnd }
            lockedImageIsBlur={ lockedImageIsBlur }
            onClickUpdate={ onClickUpdate }
            screenWidth={ screenWidth }
            isEditPage={ isEditPage }
         />
      )

      return <>
         {
            !withoutModalWraper && (
               <ResponsiveModal
                  headerTitle={ isEditebl ? 'Edit photoset' : 'New photoset' }
                  headerLeftIconAction={  () => { isEditebl ? onCloseModal() : onCloseRedirect() } }
                  headerLeftIconName='close'
                  width='642px'
                  height={ height > 750 ? '697px' : height - 100 + 'px' }
                  footerButtonText={ photpsModalButton ? 'Continue' : null }
                  photosCount={ photosLength }
                  isEditebl={ true }
                  footerIconName={ photpsModalButton && !isButtonLoading ? 'arrow-right' : '' }
                  footerIconPosition='left'
                  foooterButtonAction={ photpsModalButton ? () => onContinue() : null }
                  onClose={ onCloseModal }
                  isDisabled={ !!isLoading }
                  contentClass='py-5'
                  withoutFooter={ !photpsModalButton }
                  isLoading={ isButtonLoading }
                  isCloseAction={ false }
               >
                  {view}
               </ResponsiveModal>
            )
         }

         {
            withoutModalWraper && (
               <div
                  className={ cx({
                     'photos-content-wraper': !isEditPage,
                  })
                  }> {view} </div>
            )
         }
         {
            isOpenConfirmModal &&
            <ConfirmModal
               onCloseModal={ _ => this.openRemoveModal(null, false) }
               isCloseAction
               type='confirm'
               icon='delete'
               withoutCloseButton
               iconColor='error'
               title='Delete photo?'
               description='Are you sure you want to delete this photo?'
               buttonText='Yes, delete'
               confirmButtonProperties={ {
                  textClassName: '!text-error',
               } }
               action={ () => {
                  onDeleteImage(this.deleteItem)
                  this.openRemoveModal(null, false)
               } }
               iconSize='2xl'
               isMobile={ screenWidth < 901 }
            />
         }
      </>
   }
}

export default React.memo(PhotosetCreateContainer);
