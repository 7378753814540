import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import IconLabel from 'common/components/elements/icon-label';
import cx from 'classnames'

const ConfirmModalContent = ({
   action,
   isLoading,
   disabled,
   descriptionColorClass,
   title,
   children,
   icon,
   iconColor,
   description,
   buttonText,
   nonPrimaryColor,
   textColor,
   cancelText,
   withoutCancelButton,
   isNeedHelpButton,
   buttonClassName,
   className,
   closeModal,
   iconSize,
   primaryButtonClassName,
   buttonIconName,
   cancelButtonAction,
   withoutConfirmButton,
   secondDescription,
   secondDescriptionClassName,
   withoutCloseButton,
   isPreventDeafault,
   titleClassName,
   closeBtnClassName,
   secondaryButtonClassName,
   titleLeading,
   descriptionLeading,
   secondButtonTitle,
   secondButtonClassName,
   secondButtonAction,
   secondButtonDisabled,
   confirmButtonProperties,
   isStopPropagation,
   headerNewElementId,
}) => {
   return (
      <>
         {
            !withoutCloseButton && (
               <div className={ `h-10 w-10 min-w-[40px] flex items-center justify-center my-2 mr-2 ml-auto ${ closeBtnClassName }` }>
                  <Button
                     iconName='close'
                     fontIconColor='secondary'
                     darkIconColor='secondary-dark'
                     backgroundColor='transparent'
                     onClick={ closeModal }
                     padding='none'
                     classNames='h-6 w-6'
                     fontIconSize='doubleLarge'
                  />
               </div>

            )
         }
         <div
            role='presentation'
            onClick={ e => {
               if(isPreventDeafault) {
                  e.preventDefault()
               }
               if(isStopPropagation) {
                  e.stopPropagation()
               }
            } }
            className={ cx({
               [`flex flex-col items-center ${ className } text-center modal-action select-text`]: true,
               'p-6': withoutCloseButton,
               'px-6 pb-6': !withoutCloseButton,
            }) }
         >
            {
               icon && (
                  <IconLabel
                     color={ iconColor }
                     isPrimary={ !iconColor }
                     name={ icon }
                     size={ iconSize }
                     className='h-10 w-10 mb-3 shrink-0'
                  />
               )
            }
            <span className={ `text-xl text-major dark:text-major-dark font-semibold ${ titleLeading } ${ titleClassName } cursor-default` }>{title}</span>

            {
               description && (
                  <span
                     dangerouslySetInnerHTML={ { __html: description } }
                     className={ `${ descriptionColorClass ? descriptionColorClass : 'text-secondary dark:text-secondary-dark' } ${ descriptionLeading }  font-normal mt-4 cursor-default` } />
               )
            }
            {
               !!secondDescription &&
               <span className={ secondDescriptionClassName }>
                  {secondDescription}
               </span>
            }
            {
               !!headerNewElementId &&
               <div id={ headerNewElementId } />
            }
            {
               children && children
            }
            {
               !withoutConfirmButton && (
                  <div className={ `h-9 ${ disabled ? 'opacity-50' : '' } ${ buttonClassName }` }>
                     <Button
                        text={ buttonText }
                        borderRadius='large'
                        backgroundColor='transparent'
                        textColor={ nonPrimaryColor ? textColor : null }
                        textSize='small'
                        onClick={ action }
                        padding='none'
                        classNames={ `h-full w-full ${ primaryButtonClassName }` }
                        isLoading={ isLoading }
                        loaderColor='blue'
                        primaryColor={ !nonPrimaryColor }
                        fontWeight='medium'
                        disabled={ disabled }
                        iconName={ buttonIconName }
                        fontIconSize='base'
                        { ...confirmButtonProperties }
                     />
                  </div>
               )
            }
            {
               !!secondButtonTitle &&
               <div className='h-9 mt-3'>
                  <Button
                     text={ secondButtonTitle }
                     borderRadius='large'
                     onClick={ secondButtonAction }
                     padding='none'
                     classNames={ `h-full w-full ${ secondButtonClassName }` }
                     fontWeight='medium'
                     textClassName='text-error dark:text-error-dark'
                     textColor='error'
                     disabledElClassName='!text-disabled'
                     textSize='small'
                     backgroundColor='transparent'
                     disabled={ secondButtonDisabled }
                  />
               </div>
            }
            {
               !withoutCancelButton && (
                  <div className='h-9 mt-3'>
                     <Button
                        text={ cancelText ||  'Cancel' }
                        borderRadius='large'
                        backgroundColor='transparent'
                        textColor={ isNeedHelpButton ? 'placeholder' : 'secondary dark:text-secondary-dark' }
                        textSize='small'
                        onClick={ (e) => {
                           if(cancelButtonAction){
                              return cancelButtonAction();
                           }
                           closeModal(e);
                        } }
                        padding='none'
                        classNames={ `h-full w-full ${ secondaryButtonClassName }` }
                        fontWeight='medium'
                     />
                  </div>
               )
            }
         </div>
      </>
   );
}


ConfirmModalContent.propTypes = {
   action: PropTypes.func,
   closeModal: PropTypes.func,
   isLoading: PropTypes.bool,
   disabled: PropTypes.bool,
   descriptionColorClass: PropTypes.string,
   title: PropTypes.string,
   children: PropTypes.any,
   icon: PropTypes.string,
   iconColor: PropTypes.string,
   description: PropTypes.string,
   buttonText: PropTypes.string,
   nonPrimaryColor: PropTypes.bool,
   textColor: PropTypes.string,
   cancelText: PropTypes.string,
   withoutCancelButton: PropTypes.bool,
   isNeedHelpButton: PropTypes.bool,
   buttonClassName: PropTypes.string,
   className: PropTypes.string,
   iconSize: PropTypes.string,
   buttonIconName: PropTypes.string,
   primaryButtonClassName: PropTypes.string,
   cancelButtonAction: PropTypes.func,
   withoutConfirmButton: PropTypes.bool,
   secondDescription: PropTypes.string,
   secondDescriptionClassName: PropTypes.string,
   withoutCloseButton: PropTypes.bool,
   isPreventDeafault: PropTypes.bool,
   titleClassName: PropTypes.string,
   closeBtnClassName: PropTypes.string,
   secondaryButtonClassName: PropTypes.string,
   titleLeading: PropTypes.string,
   descriptionLeading: PropTypes.string,
   secondButtonTitle: PropTypes.string,
   secondButtonClassName: PropTypes.string,
   secondButtonAction: PropTypes.func,
   secondButtonDisabled: PropTypes.bool,
   confirmButtonProperties: PropTypes.object,
   isStopPropagation: PropTypes.bool,
   headerNewElementId: PropTypes.string,
};

ConfirmModalContent.defaultProps = {
   buttonText: 'Discard changes',
   className: '',
   buttonClassName: 'mt-6',
   primaryButtonClassName: '',
   buttonIconName: '',
   withoutConfirmButton: false,
   withoutCloseButton: true,
   secondDescription: '',
   secondDescriptionClassName: '',
   isPreventDeafault: true,
   titleClassName: '',
   closeBtnClassName: '!w-[fit-content]',
   secondButtonTitle: '',
   confirmButtonProperties: {},
   headerNewElementId: '',
};

export default ConfirmModalContent;
