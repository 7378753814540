import React, { useEffect, useRef, useState } from 'react';
// import Icon from 'common/components/elements/icons';
import PropTypes from 'prop-types';
import AddNewPhotosetCard from './add-new-photoset-card';
import {
   sortableContainer,
   sortableElement,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import './style.scss';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import IconLabel from 'common/components/elements/icon-label';
import Button from 'common/components/elements/buttons/primary';
import cx from 'classnames'

const SortableItem = sortableElement(({
   cover,
   photo,
   removePhoto,
   makeCover,
   setActiveCard,
}) => {
   return (
      <AddNewPhotosetCard
         cover={ cover }
         className='z-10'
         selectCard={ (id) => setActiveCard(id) }
         photo={ photo }
         removePhoto={ removePhoto }
         makeCover={ () => makeCover(photo) }
      />
   )
}
);

const SortableContainer = sortableContainer(({ children }) => {
   return <>{children}</>;
});

const AddNewPhotoset = ({
   photos,
   activeCard,
   setActiveCard,
   fileUpload,
   removePhoto,
   cover,
   makeCover,
   // gotToContinue,
   onSortPhotosEnd,
   photosCount,
   isEditebl,
   lockedImageIsBlur,
   onClickUpdate,
   // className,
   screenWidth,
   isEditPage,
}) => {

   let inputFile = null
   const [inputValue, setInputValue] = useState('')
   const [dragActive, setDragActive] = useState(false)
   const [openUploader, setopenUploader] = useState(false)
   const [loadMore, setLoadMore] = useState(false)
   let headerElementRef = useRef(null)


   useEffect(() => {
      if(isEditPage && photos?.length > 9) {
         setLoadMore(true)
      }
   // eslint-disable-next-line
   },[isEditPage])

   const onSortEnd = ({ oldIndex, newIndex }) => {
      const newData = arrayMoveImmutable(photos, oldIndex, newIndex)
      if(oldIndex !== newIndex){
         onSortPhotosEnd(newData)
      }
   }

   const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if(e.type === "dragenter" || e.type === "dragover") {
         setDragActive(true);
      } else if(e.type === "dragleave") {
         setDragActive(false);
      }
      setDragActive(dragActive)
   };

   const handleDrop = function(e) {
      // if(isDemoMode) return
      e.preventDefault();
      e.stopPropagation();
      if(e.dataTransfer.files && e.dataTransfer.files[0]) {
         e.dataTransfer.effectAllowed = "all"
         e.dataTransfer.dropEffect = "move"
         // console.log(e.dataTransfer.files);
         fileUpload(e.dataTransfer.files)
      }
   };

   return (
      <div className={ cx({
         'relative max-lg:pb-5 flex flex-col gap-4': true,
         '': isEditPage,
      }) }>
         <div
            className='flex gap-4 flex-wrap items-center'
            ref={ headerElementRef }
         >
            {
               isEditPage &&
               <Button
                  text={ !openUploader ? 'Add new photos' : 'Done' }
                  backgroundColor='action'
                  iconName={ !openUploader ? 'add' : '' }
                  iconPosition='left'
                  borderRadius='large'
                  onClick={ () => setopenUploader(!openUploader) }
                  classNames='px-[18px] py-2 h-10 !w-fit'
                  textMarginX='0'
                  textClassName={ !openUploader ? 'ml-2' : '' }
                  // disabled={ isDemoMode }
                  textSize='base'
                  // dataToolTipContent={ isDemoMode ? 'Not available in demo' : '' }
               />
            }
            <CheckboxLabel
               label='Blur photoset when access is locked'
               name='locked_image_is_blur'
               checked={ lockedImageIsBlur }
               onChange={ onClickUpdate }
               labelClassName='!w-fit'
               padding='0'
            />
         </div>
         <div
            className={ cx({
               'flex flex-col gap-4': true,
               'lg:overflow-y-scroll ams-custom-scrool': isEditPage,
            }) }
            style={ {
               maxHeight: screenWidth > 1024 ? `calc(100vh - 56px - ${ headerElementRef?.current?.clientHeight + 60 }px)` : '',
            } }
         >
            <SortableContainer
               helperClass='sortable-helper'
               useDragHandle
               axis='xy'
               onSortEnd={ onSortEnd }
            >
               <div
                  id='photos-wrapper'
                  className={ cx({
                     'grid gap-5 w-full grid-cols-1 ': true,
                     'md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4': !isEditPage,
                     'sm:grid-cols-2 tablet:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4': isEditPage,
                     '!grid-cols-1': screenWidth < 1145 && screenWidth > 1024,
                  }) }
               >
                  <div
                     onDragEnter={ handleDrag } onDragLeave={ handleDrag } onDragOver={ handleDrag } onDrop={ handleDrop }
                     className={ cx({
                        'relative overflow-hidden rounded-lg h-auto': true,
                        '!hidden': !openUploader && isEditPage,
                     }) }
                  >
                     <div className='aspect-ratio-padding' />
                     <div
                        className='absolute p-1 top-0 right-0 h-full w-full bg-panel hover:bg-hover'
                        onClick={ () => {
                           inputFile.click()
                        } }
                        role='presentation'
                     >
                        <div className='relative items-center justify-center cursor-pointer flex flex-col w-full h-full'>
                           <div className='absolute w-full h-full rounded-lg border border-dashed border-placeholder'>

                           </div>
                           <div className='flex flex-col justify-center items-center gap-2 z-10 px-2'>
                              <input
                                 type='file'
                                 hidden
                                 multiple
                                 value={ inputValue }
                                 ref={ (input) => { inputFile = input; } }
                                 onChange={ (e) => {
                                    fileUpload(e.target.files);
                                    setInputValue('')
                                 } }
                                 accept='image/png, image/jpeg, image/jpg, image'
                              />
                              <IconLabel
                              // color={ isDemoMode ? 'disabled' : 'secondary' }
                                 color='secondary'
                                 name='cloud-upload-new'
                                 size='2xl'
                                 className='h-10 w-10'
                              />
                              {/* <span className={ `text-sm font-medium text-${ isDemoMode ? 'disabled' : 'secondary' }` }> {screenWidth > 700 && 'or drag and drop'} multiple image files here</span> */}
                              <span className='text-sm font-medium text-action text-center'>
                                 Click to upload
                                 <span className={ `text-sm font-medium text-secondary` }> {screenWidth > 700 && 'or drag and drop'} multiple image files here</span>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  {
                     photos.map((photo, index) => {
                        if(isEditPage && !!loadMore && index > 8) return null
                        return (
                           <SortableItem
                              onSortEnd={ onSortEnd }
                              useDragHandle
                              shouldUseDragHandle={ true }
                              key={ `item-${ index }` }
                              index={ index }
                              photo={ photo }
                              cover={ cover && cover.id === photo.id }
                              setActiveCard={ (id) => setActiveCard(id, photo.id === activeCard) }
                              removePhoto={ removePhoto }
                              makeCover={ () => makeCover(photo) }
                           />
                        );
                     })
                  }
               </div>
            </SortableContainer>
            {
               loadMore &&
               <Button
                  text={ 'Show all' }
                  backgroundColor='transparent'
                  borderRadius='large'
                  onClick={ () => setLoadMore(false) }
                  classNames='px-3.5 h-9 !w-fit mx-auto'
                  textMarginX='0'
                  textClassName={ 'text-secondary' }
                  textSize='small'
               />
            }
         </div>
      </div>
   );
};

AddNewPhotoset.propTypes = {
   photos: PropTypes.array,
   activeCard: PropTypes.any,
   setActiveCard: PropTypes.func,
   fileUpload: PropTypes.func,
   removePhoto: PropTypes.func,
   makeCover: PropTypes.func,
   // gotToContinue: PropTypes.func,
   onSortPhotosEnd: PropTypes.func,
   cover: PropTypes.any,
   photosCount: PropTypes.number,
   isEditebl: PropTypes.bool,
   lockedImageIsBlur: PropTypes.bool,
   onClickUpdate: PropTypes.func,
   screenWidth: PropTypes.number,
   isEditPage: PropTypes.bool,
};

AddNewPhotoset.defaultProps = {
   photos: [],
   isEditebl: false,
   fileUpload: () => {},
};

export default React.memo(AddNewPhotoset);
